import {deleteSpaceAll, deleteSpaceFirst, isLicensePlate, poneNeg} from './constant'

export const removeFirstAndEnd=(value)=>{//去除首尾空格
  if(!value) return '';
  return value.replace(deleteSpaceAll, "");
};

export const removeFirst=(value)=>{//去除首部空格
  if(!value) return '';
  return value.replace(deleteSpaceFirst, "");
};

export const isLicensePlatePlace=(value)=>{
  if(!value) return '';
  return isLicensePlate.test(value);
} 

export const ispone=(value)=>{ //手机号验证
    if(!value) return '';
    return poneNeg.test(value);
}