import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';
import {Upload } from 'antd';
import copy from 'copy-to-clipboard';
import { Player, ControlBar,BigPlayButton } from 'video-react';

import "../../../../node_modules/video-react/dist/video-react.css"
import AbieButton from './kits/button'
import{serviceUnitStatus,serviceGroup} from "../../../shared/utils/status"
import {ismobile,isWeixing} from "../../../shared/utils/common"
import {formatDateToUtc} from '../../../shared/utils/common'
import{banners,getDtail,getStated,saveInformationImage,edictTex,reloads,showerAser} from '../actions/index'
import {AbieVideo} from './kits'

class Mydetal extends Component {
  constructor(props) {
    super(props);
    const methods = ['copyUrl2','textarea','textarea1','previewVideo','submitnot','goLuder','hideEr','showd',
    'toIndex','beforeUpload','handleChange','edict','hoide','closeMo','hoided','onBlurs','shows','save'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      currenCont:0,
      currenC1:'',
      currenC:'',
      currenC2:'',
      currenC3:'',
      baseNuber:"",
      stdId:0,
      filesData:{},
      useData:{},
      currenCont1:0,
      show:false,
      showed:false,
      shower:false,
      flss:true,
      showeds:false,
      imgUrlTo:'',
      shwoPage:true,
      showers:false
    };
  }
  save() {
    var alink = document.createElement("a");
    alink.href = this.img.src;
    alink.click();
  }
  hideEr(){
    this.setState({
      showeds:false
    })
  }
  closeMo(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.setState({
      shower:true,
    })
  }
  showd(){
    this.setState({
      showers:true
    })
  }
  hoided(){
    this.setState({
      shower:false,
      showers:false
    })
  }

  shows(){
    this.player.play()
    this.setState({
      flss:false
    })
  }

  hoide(){
    this.setState({
      show:false
    })
  }

  beforeUpload(file,fileList){
    var videos = document.getElementsByTagName('video');
    for (var j = videos.length - 1; j >= 0; j--) {
      videos[j].pause();
    }
    this.setState({
      showed:true,
      flss:true
    });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let thist=this
    reader.onload = function(e) {
      thist.setState({
        baseNuber:e.target.result,
      });
    };
    const fileSiz=file.size/1024/1024
    if(fileSiz>200){
      Toast.info('请上传低于200M文件', 3);
    }else{
      reloads(fileList,this.state.stdId).then((res)=>{
        thist.props.getDtail(thist.state.stdId)
        thist.props.getStated(thist.state.useData.StudentID,thist)
        thist.setState({
          showed:false,
        });
      })
      // .then((res) => {
      //   console.log('res',res.data.ResultInfo.Data)
      //   thast.setState({
      //     worksid:res.data.ResultInfo.Data
      //   })
      //   if(res.data.ResultInfo.ErrCode===0){
      //     thast.setState({
      //       showPlauer:true,
      //       showed:false
      //     });
      //     Toast.info('上传成功', 3);
      //     this.props.getStated(this.state.useData.StudentID,thast)
      //     this.setState({
      //       show:false,
      //       showed:false,
      //     })
      //   }else if(res.data.ResultInfo.ErrCode===1){
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false,
      //       showed:false,
      //       showeds:true
      //     });
      //   }else{
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false
      //     });
      //   }
      // }).catch(()=>{
      //   this.setState({
      //     show:false,
      //     showed:false,
      //     showeds:true
      //   })
      // })

      this.setState({ 
        filesData:fileList[0],
      })
    }
  }

  edict(){
    const{WorksName,WorksDescribe}=this.props.detail
    this.setState({
      show:true,
      currenC: WorksName,
      currenC1: WorksDescribe,
      currenCont:WorksName.length,
      currenCont1:WorksDescribe?WorksDescribe.length:0,
    })
  }
  goLuder(){
    this.props.history.push({pathname: `/`})
    localStorage.removeItem("useData");
  }

  toIndex(){
    this.props.history.push({pathname: `/`})
  }

  textarea(e){
    if(e.target.value.length<=30){
      this.setState({
        currenCont:e.target.value.length,
        currenC:e.target.value
      })
    } 
  }

  textarea1(e){
    if(e.target.value.length<=50){
      this.setState({
        currenCont1:e.target.value.length,
        currenC1:e.target.value
      })
    }
  }


  previewVideo = (file) => {
    // 建议判断下视频大小及格式，太大的可能会有问题
    const reader = new FileReader();
    reader.onload = (evt) => {
      this.videoCode.src = evt.target.result;
    };
    reader.readAsDataURL(file);
}
  
componentDidMount() {
  if (navigator.onLine){}else { Toast.info('当前网络不佳，请稍后再试', 3);}
  // if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      }) 
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    const stdId=this.props.match.params.id
    showerAser(stdId,this)
    this.setState({
      stdId
    })
    this.props.getDtail(stdId)
    const {StudentID}=useData
    const that=this
    this.props.getStated(StudentID,that)
    let thetime = '2020-06-01 00:45:00';
    let d=new Date(Date.parse(thetime .replace(/-/g,"/")));
    let curDate=new Date();
    d <=curDate&&this.setState({shwoPage:false})
  };
  state = {
    loading: false,
  };
  
  handleChange = ({file,fileList}) => {

  };

  copyUrl2(){
    copy(this.ahrfCode.innerHTML)
    Toast.info('已复制好，可贴粘', 3);
  }

  submitnot(){
    const {currenC1,currenC}=this.state
    if(currenC){
      edictTex(this.props.detail.WorksID,currenC,currenC1).then((res)=>{
        this.props.getDtail(this.props.match.params.id)
        this.setState({
          show:false,
        })
      })
    }else{
      Toast.info('请填写视频中所表演的节目名字！', 3);
    } 
  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  render() {
    const {WorksName,WorksDescribe,WorksGroup,VoteNum,NetUrl,NumPlate}=this.props.detail
    const {show,showed}=this.state
    console.log("this.props.types.Status",this.props.types.Status)
    return (
      <div className="loadbacd">
        {this.state.shower&&<div>
          <div className="ab-modal-warp noTrans">
            <div className="ab-modal-mask" onClick={this.hoided}></div>
            <img src={require('../../../assets/images/shower.png')}/>
					</div>
        </div>}
        {this.state.showeds&&<div>
          <div className="ab-modal-mask" onClick={this.hideEr}></div>
          <div className="ab-modal-warp">
            <div>
              <div className="shoDetal">
                <img style={{width:"26rem"}} src={require('../../../assets/images/scan.png')} alt=""></img>
                <AbieButton className="load-sd strd" onClick={this.hideEr}>知道了</AbieButton>
              </div>
            </div>
					</div>
        </div>}
        {this.state.showers&&<div>
          <div className="ab-modal-warp zhus">
            <div className="ab-modal-mask" onClick={this.hoided}></div>
            <div className="zsu">
              <img src={require('../../../assets/images/xzss.png')}/>
              <div> 长按保存证书</div>
            </div>
					</div>
        </div>}
        {this.state.showeds&&<div>
          <div className="ab-modal-mask" onClick={this.hideEr}></div>
          <div className="ab-modal-warp">
            <div>
              <div className="shoDetal">
                <img style={{width:"26rem"}} src={require('../../../assets/images/scan.png')} alt=""></img>
                <AbieButton className="load-sd strd" onClick={this.hideEr}>知道了</AbieButton>
              </div>
            </div>
					</div>
        </div>}
        {showed&&<div>
          <div className="ab-modal-mask"></div>
          <div className="ab-modal-warp ab-warp">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt="" srcSet=""/>
                <div>
                  上传中...<br></br>请勿关闭此页面
                </div>
            </div>
					</div>
        </div>}
        {show&&<div>
          <div className="ab-modal-warp ab-warp shows">
            <div className="ab-modal-mask" onClick={this.hoide}></div>
            <div className="showTrue">
              <div className="up-insert">
                <div>*</div>
                <textarea onBlur={this.onBlurs} name=""  value={this.state.currenC} cols="30" rows="2" onChange={this.textarea} placeholder="视频中所表演的节目名字"></textarea>
                <p><span>{this.state.currenCont}</span>/<span>30</span></p>
              </div>
              <div className="up-insert">
                <div> </div>
                <textarea onBlur={this.onBlurs} value={this.state.currenC1} name="" cols="30" rows="4" onChange={this.textarea1} placeholder="写下你的参赛宣言,让你的优秀被更多人发现!"></textarea>
                <p><span>{this.state.currenCont1}</span>/<span>50</span></p>
              </div>
              <AbieButton className="load-sd btmss whites"  onClick={this.submitnot}>确定</AbieButton>
            </div>
					</div>
        </div>}
        <div className="sef-head">
          <img src="" src={require('../../../assets/images/def_tx1.png')} alt=""/>
          <div>
            <p>{this.props.types.Student?this.props.types.Student.StudentName:'暂无数据'}</p>
            <p>{this.props.types.Student?this.props.types.Student.OrgName:'暂无数据'}</p>
          </div>
          <div>
            <button className={"btn-out"} onClick={this.goLuder}>登出账号</button>
          </div>
        </div>
        <div className="form-content">
          {this.state.shwoPage?<div className="tab-lit-cont lpcont apply player plaer">
            <div className="up-top-tipd">
                <img src={require('../../../assets/images/upImag-25.png')} alt=""/>
            </div>
            <div className="up-bac">
                <div className="up-sp up-sps">
                  {/* <AbieVideo
                      videoUrl={this.state.baseNuber?this.state.baseNuber:NetUrl}
                      videoHeight="100%"
                      className='videBox'
                    >
                  </AbieVideo> */}

                 {<Player
                      // src={this.props.types.Works&&this.props.types.Works.NetUrl?this.props.types.Works.NetUrl:''}
                      src={this.props.detail.NetUrl}
                      playsInline={true}
                      className={(!this.state.flss&&!showed)?'oopay':'oopays'}
                      fluid={true}
                      type="video/mp4"
                      ref={player => {
                        this.player = player;
                      }}
                    >
                      <BigPlayButton position="center" />
                      <ControlBar autoHide={true} />
                    </Player>}
                    {(this.state.flss||showed)&&<div className="plauAbie abiepl" onClick={this.shows}>
                      {/* <img src={require('../../../assets/images/play-26.png')}/> */}
                    </div>}  
                </div>   
            </div>
            
            <div className="fans">
              <div>{this.props.types.Student?this.props.types.Student.StudentName:'暂无数据'}<button 
              style={{backgroundColor:serviceUnitStatus(WorksGroup)}}
              className="btn-seach2"
              >{WorksGroup}</button>
              </div>
              {this.props.detail.OpenRematch?<Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept="video/*"
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
                previewFile={this.previewFiled}
                capture={false}
              >
                <img src={require('../../../assets/images/reUpload-27.png')}></img> 重新上传
              </Upload>:<div style={{width:"12rem",color:"#a5a5a5",fontSize:"1.4rem"}}></div>}
            </div>
            
            {/* <div className="up-insert pps" style={{background:"none"}}>
              <div style={{marginTop:"0rem", textOverflow:"ellipsis", whiteSpace:"nowrap",width:"100%",textAlign:"center"}}>标题:{this.state.currenC2?this.state.currenC2:WorksName}</div>
            </div> */}
            <div className="up-insert pps uipage">
              <div className="tipsa">{this.state.currenC2?this.state.currenC2:WorksName}</div>
              <div className="tipsa">{this.state.currenC3?this.state.currenC3:WorksDescribe}</div>
              <div>
                {this.props.detail.OpenRematch&&<div onClick={this.edict}><img src={require('../../../assets/images/reEld-28.png')}></img> 重新编辑</div>}
              </div>
                
            </div>
            <div className="pao-tips">
            集赞数:<span>{VoteNum}</span>
            </div>
            <div className="showered" onClick={this.closeMo}>
              <AbieButton className="load-sd load-fx" ><img src={require('../../../assets/images/fx.png')} alt=""/>集点赞 上榜单</AbieButton>

            </div>
          </div>:<div className="tab-lit-cont lpcont apply player plaer seeks">
            {this.props.types.Status!==6?<div>
                <img className="passwd" src={require('../../../assets/images/tr100.png')} alt=""/>
                <div className="groupss" onClick={this.showd}><div><a>点击此处查看证书</a></div></div>
              </div>:
            <div>
              <img className="passwd" src={require('../../../assets/images/in100.png')} alt=""/>
            <div className="groups"><div>您的组别: <span> {WorksGroup} </span>（{serviceGroup(WorksGroup)}）</div><div>您的出场顺序: <span>{NumPlate}</span></div></div>
            </div>}
          </div>}
        </div>
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
        <div onClick={this.toIndex} className="totps">
          <img src={require('../../../assets/images/goIndex.png')} alt="" srcSet=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,detail,types}} = state;
  return {bannerResource,detail,types}
};

const mapDispatchToProps =  {
  banners,
  getDtail,
  getStated
};

export default connect(mapStateToProps, mapDispatchToProps)(Mydetal);