import React, {Component} from 'react';
import FileViewer from 'react-file-viewer';
import {ismobile,isWeixing} from "../../../shared/utils/common"

class FileViewerd extends Component {

    componentDidMount(){
      if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
        document.title = `用户协议`;
    }
    render() {
    return (
      <div className="userPromis">
         {/* <FileViewer
            fileType={"pdf"}
            filePath={require('../../../assets/images/ys.pdf')}>
            </FileViewer> */}
            <div dangerouslySetInnerHTML={{__html: `<div class=WordSection1 style='layout-grid:15.6pt'>

<p class=MsoNormal align=center style='text-align:center;line-height:150%'><b><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体;'>Speak Out miniSite</span></b><b><span style='font-size:15.0pt;
line-height:150%;font-family:宋体'>用户协议与隐私协议</span></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:150%'><b><span
lang=EN-US style='font-size:15.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>一、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>协议条款的确认和接受</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>Speak Out
miniSite</span><span style='font-size:12.0pt;line-height:150%;font-family:宋体'>是由爱贝企业发展（上海）有限公司（“本公司”）开发的，由本公司运营的，旨在服务于爱贝英语<span
lang=EN-US>Speak Out</span>风采盛典项目的网页（“网页”）。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户：下列人员可经过申请成为网页用户</span></p>

<p class=MsoListParagraph style='margin-left:36.0pt;text-indent:-36.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>（1）<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本公司<span lang=EN-US>Speak
Out</span>风采盛典项目成员；</span></p>

<p class=MsoListParagraph style='margin-left:36.0pt;text-indent:-36.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>（2）<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>爱贝英语全国分中心人员及中心学员。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>申请<span lang=EN-US>/</span>使用须知：在使用本网页之前，请您务必认真阅读、充分理解本协议下的所有条款与内容，包括免除或者限制本公司责任的免责条款及对用户的权利限制条款，否则请不要点击同意按键或开始使用本网页。在您点击同意按键，或是有任何使用本网页的行为，即表示您不可撤销地同意本协议（包括本协议不时修改的版本，下同）所有条款，愿意接受本协议全部条款的约束。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>特别说明：如果您是未成年用户，您需要和您的监护人一起仔细阅读本协议，并在征得您的监护人同意后，使用本公司的网页。如果您使用或继续使用本网页服务，提交个人信息，都表示您已经获得监护人的许可，您和您的监护人均同意本公司按照本协议收集、使用和分享您的个人信息。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;line-height:
150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:宋体'>如果您是未成年用户的监护人，请您仔细阅读和选择是否同意本协议。如果您不同意本协议的内容，您应要求您孩子立即停止使用本网页。您孩子使用或继续使用本网页，提交个人信息，都表明您同意本公司按照本协议收集、使用和分享您孩子的个人信息。如果本公司发现在未事先获得监护人同意的情况下收集了未成年用户的个人信息，则会设法尽快删除相关数据。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>版本更新：本协议将不定时进行更新，更新后的协议版本将会公布在本网页中，不再另行通知用户，请您及时关注版本更新情况。一旦新版本协议公布即立即替代旧版本协议（新版本协议中另有注明的除外）。如果您不接受新版本协议的全部或任何条款，请您立即停止使用本网页，否则将被视为您对接受更新后协议版本的约束的再次确认及承诺。</span></b></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>二、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>信息收集和使用</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们仅会出于实现产品及服务功能的目的，根据合法、正当、必要的原则，收集和使用您定目的收集而来的信息用于其他目的时，我的个人信息。如果我们要将信息用于本政策未载明的其它用途或要将基于特们将以合理的方式通知您，并会事先征求您的同意。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>我们收集您的信息是为了给您参与<span
lang=EN-US>Speak Out</span>提供更好的体验。我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>如果您在使用网页时需通过第三方关联账户登录或注册，我们会请求第三方提供您的账户昵称、头像、账号、性别等信息；并且，我们可能需要您提供手机号码等个人信息。若您拒绝提供上述信息，会导致您无法使用我们的产品或服务。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>当您使用我们的小程序时，我们会自动收集您对我们小程序的详细使用情况，作为有关网络日志保存。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>三、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>用户账号</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户有义务保证账号安全，该账号所进行的一切活动均视为用户自己的行为，由此引起的任何损失或后果，由用户自行承担全部责任，本公司不承担任何责任。如果用户发现账号遭到未授权的使用或发生其他任何安全问题，请立即联系本公司或所属爱贝英语分中心进行解决处理。因黑客行为或用户的保管疏忽导致账号被非法使用，本公司不承担任何责任。</span></p>

<p class=MsoNormal><span lang=EN-US style='font-size:12.0pt;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户不得将自己的账号以转让、出借、授权等方式有偿或无偿提供给第三方操作和使用，否则用户应当自行承担因违反此条款而遭致的任何损失。若违反本条款对本公司<span
lang=EN-US>/</span>他人造成损害的，用户应与实际使用人承担连带赔偿责任，同时本公司保留追究用户违约责任的权利。</span></b></p>

<p class=MsoNormal style='line-height:150%'><b><span lang=EN-US
style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>四、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>使用规则</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户在使用网页过程中，必须遵循国家的相关法律法规，不得发布危害国家安全，色情，暴力，侵犯知识产权、他人名誉权等内容。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本公司有权根据自己的判断删除用户上载的任何具有违法性、侵权性、不当性之内容，或者任何其他本公司认为有不妥之内容，而无需事先通知上传的用户，且无需对用户做出任何赔偿或补偿，并且本公司有权依据法律、法规保存相关信息并向有关部门报告。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户同意：对于用户上传、发布到本网页上的文本、图片、图形、音频、视频等可通过本网页获取的所有数据、文件、资料等，如果本公司对其不享有著作权或是任何所有权，则上传的用户同意本公司享有使用权，即可以基于任意的目的予以利用，包括但不限于用于品牌宣传、统计和比较分析，以及将此等内容编入当前已知或以后开发的其他任何形式的作品、媒体、系统或技术中，使用的方式包括但不限于复制，修改，改编，出版，翻译，传播和展示等。另外，本公司亦有权将前述内容提供给本公司的关联公司、合作方、被授权方依照前述目的使用。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户同意：本公司有权在任何时候修改、暂停、中止或终止本网页全部或部分服务而无需事先对用户做出通知，且无需对用户做出任何赔偿、补偿。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>用户同意并授权：本公司有权将用户上传到本系统中个人信息（（包括但不限于姓名、电子邮箱、联系电话、联系地址等）自行使用或是提供给本公司的关联公司、合作方、被授权方使用。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本公司及本公司的关联公司、合作方、被授权方有权使用用户个人信息向用户发送如下信息：</span></b></p>

<p class=MsoListParagraph style='margin-left:36.0pt;text-indent:-36.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>（1）<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>各类重要通知信息，包括但不限于<span
lang=EN-US>Speak Out</span>活动安排、培训安排、课程安排、修改密码提示等。</span></b></p>

<p class=MsoListParagraph style='margin-left:36.0pt;text-indent:-36.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>（2）<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>商品和服务广告、促销优惠等商业信息。</span></b></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><b><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>五、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>信息管理</span></b></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>1. </span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>共享：我们不会向任何第三方公司、组织和个人共享您的个人信息，但以下情况除外：</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）获得您或您监护人的明确同意后，我们会与其他方共享您的个人信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>3</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）为提供、维护及改善我们的产品或服务向合作伙伴及其他第三方分享您的信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>4</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，我们可能与其他公司和组织交换信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>5</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>6</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）依本协议相关约定而共享。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>2. </span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>转让：我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）在获取您或您的监护人明确同意的情况下转让，我们会向其他方转让您的个人信息；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>3. </span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>公开披露：我们仅会在以下情况下，公开披露您的个人信息：</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）获得您或您的监护人明确同意后；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>4. </span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>例外情形：您知悉并同意在以下情况下，我们共享、转让、公开披露个人信息可无需事先征得您的授权同意：</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）与国家安全、国防安全相关的；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）与公共安全、公共卫生、重大公共利益相关的；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>3</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）与犯罪侦查、起诉、审判和判决执行等直关的；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>4</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>5</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）您自行向社会公众公开的个人信息；</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>6</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>六、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>信息保护</span></b></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失，我们将尽力确保或担保您发送给我们的任何信息的安全性。我们会采取一切合理可行的措施，确保未收集无关的个人信息。若发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会采取合理、有效的方式及时将事件相关情况发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span></p>

<p class=MsoNormal style='text-indent:24.0pt'><span lang=EN-US
style='font-size:12.0pt;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='text-indent:0cm;line-height:150%'><span
lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>七、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>权利声明</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本网页及本网页下的相关产品或者服务，及上传、发布到本网页上的相关文本、图片、图形、音频和<span
lang=EN-US>/</span>或视频资料等受著作权法、商标法、专利法、信息网络传播权保护条例、反不正当竞争法等相关法律、法规的保护。如果前述相关内容未含权利声明，并不代表权利人对其不享有权利和<span
lang=EN-US>/</span>或不主张权利，用户应该根据法律法规及诚信原则尊重权利人的合法权益并合法利用该内容。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>未经本公司许可，用户不得以任何方式或理由对本网页、产品、服务、信息、文字的任何部分进行复制、修改、抄录、传播或与其他产品捆绑使用、销售，或者以超级链路连接或传送、存储于信息检索系统、任何存储介质或者其他任何商业目的及非商业目的的使用。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本网页中使用和显示的商标和标识，受法律保护，未经权利人许可，用户不得以任何方式使用该商标和标识。
</span></p>

<p class=MsoNormal style='line-height:150%'><b><span lang=EN-US
style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>八、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>责任限制声明</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本公司不保证本网页下内容、产品、服务或其他材料的真实性、完整性、安全性和合法性。用户应谨慎判断并自担风险，避免任何因使用或信赖此类内容、产品、服务或其他材料而造成的任何直接或间接损失，对于该等损失本公司不承担任何责任。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>其他用户通过本网页所发布的任何内容并不反映或代表本公司的观点、建议、政策或是任何暗示，与本公司无关。用户应谨慎判断并自担风险，避免任何因使用或信赖此类内容、产品、服务或其他材料而造成的任何直接或间接损失，对于该等损失本公司不承担任何责任本公司对此不承担任何责任。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本网页内容仅供参考，不作为任何行为的指导和建议，用户对自己的决策所产生的法律后果、事实后果由用户自行承担。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>不论在何种情况下，本公司对于由于信息网络设备故障、信息网络连接故障、智能终端、通讯或其他系统的故障、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令、其他不可抗力或第三方的作为与不作为而造成的本网页不能服务或中断、延迟服务，及本小程序、本网页内容的遗漏、误导、错误、遭篡改、伪造、变造等，均不承担责任。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;line-height:
150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:
宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>九、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>第三方链接及服务</span></b></p>

<p class=MsoNormal style='line-height:150%'><b><span style='font-size:12.0pt;
line-height:150%;font-family:宋体'>网页中可能保留、发布第三方平台的链接及第三方服务，是否访问这些链接或接受相应的服务将由用户自己作出决定，本公司并不就这些链接或服务所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。本公司没有审查过任何第三方平台，对这些平台及其内容不进行控制，亦不负任何责任。如果用户决定访问任何与链接相关的平台，或购买任何第三方产品与服务，其可能带来的后果和风险由用户自己承担。</span></b></p>

<p class=MsoNormal style='line-height:150%'><b><span lang=EN-US
style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>十、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
宋体'>侵权投诉</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>任何单位或个人认为本网页中内容可能涉嫌侵犯其享有的知识产权，可向本公司提出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。本公司在收到上述完整文件后，有权依法删除侵权内容或断开侵权链接。</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>上述侵权投诉文件发送至如下电子邮箱：【<span
lang=EN-US>marketing@abiechina.com</span>】。</span></p>

<p class=MsoNormal style='line-height:150%'><b><span lang=EN-US
style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>十一、<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>法律适用及争议解决</span></b></p>

<p class=MsoNormal style='line-height:150%'><span lang=ZH-TW style='font-size:
12.0pt;line-height:150%;font-family:宋体'>本</span><span style='font-size:12.0pt;
line-height:150%;font-family:宋体'>协议</span><span lang=ZH-TW style='font-size:
12.0pt;line-height:150%;font-family:宋体'>受中国大陆法律管辖，与本</span><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>协议</span><span
lang=ZH-TW style='font-size:12.0pt;line-height:150%;font-family:宋体'>有关的任何争议应通过友好协商解决。如不能协商解决，任何一方有权向上海仲裁委员会申请仲裁。</span></p>

<p class=MsoNormal style='line-height:150%'><span lang=EN-US style='font-size:
12.0pt;line-height:150%;font-family:宋体'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>十二、<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本协议部分条款、内容的特别说明</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本协议中加粗部分可能存在责任免除或限制减轻本公司责任、加重用户责任或限制用户权利的内容，用户确认本公司已经对该等内容全部向用户做出了明确提示。若您对该等内容的含义有任何疑问或问题，请先不要点击同意按键或是使用本网页，您可以将相关疑问或是问题通过邮箱或是电话的方式联系本公司（联系邮箱：【<span
lang=EN-US>marketing@abiechina.com</span>】、联系电话【<span lang=EN-US>4000881298</span>转市场部】），本公司会派专人作明确说明、解释。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>请您务必注意，如您点击同意或是开始使用本网页，即表明您清楚地理解了本协议的全部内容，对本协议的全部内容的含义没有任何疑问或问题，并愿意接受该等条款及内容的约束。</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:-21.0pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:12.0pt;line-height:150%;font-family:宋体'>本协议内容与本公司与用户之前的约定（如有）不一致的，以本协议的内容为准。</span></b></p>

<p class=MsoNormal style='line-height:150%'><b><span lang=EN-US
style='font-size:12.0pt;line-height:150%;font-family:宋体'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b></p>

<p class=MsoNormal style='line-height:150%'><span lang=EN-US style='font-size:
12.0pt;line-height:150%;font-family:宋体'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:12.0pt;line-height:150%;font-family:宋体'>爱贝企业发展（上海）有限公司</span></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:289.15pt;
line-height:150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;
font-family:宋体'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;line-height:
150%'><b><span lang=EN-US style='font-size:12.0pt;line-height:150%;font-family:
宋体'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;</span></b></p>

<p class=MsoNormal align=left style='text-align:left'><b><span lang=EN-US
style='font-size:12.0pt;font-family:宋体'>&nbsp;</span></b></p>

</div>`}}></div>
      </div>
    );
  }
}

export default FileViewerd;