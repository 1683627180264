import moment from 'moment';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const onlyDateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'HH:mm'

export const formatDate=(value,format=dateFormat)=>{
  if(!value)return '';
  return moment(new Date(value*1000)).format(format);
};

export const formatDateBase=(value,format=dateFormat)=>{
  if(!value)return '';
  return moment(value).format(format);
};

export const onlyDate=(value,format=onlyDateFormat)=>{
  if(!value)return '';
  return moment(value).format(format);
};

export const formatTimeBase=(value,format=dateTimeFormat)=>{
  if(!value)return '';
  return moment(value).format(format);
};

export const formatDateTime = (date)=>{  
  let y = date.getFullYear();  
  let m = date.getMonth() + 1;  
  m = m < 10 ? ('0' + m) : m;  
  let d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  return y + '-' + m + '-' + d;  
};  

export const formatDateToUtc=(nowvalue)=>{
  const nowTime = new Date();
  const offset = nowTime.getTimezoneOffset()/60;
  const value = new Date(nowvalue);
  const valueNew = (value.getTime()/1000+ offset*60*60)/1000;
  const valuNewFlow =  Math.round(valueNew)
  return valuNewFlow;
};

export const titleCase=(str)=>{
  if(!str)return '';
  let arr = str.toLowerCase().split(" ");
  for (let i=0;i<arr.length;i++){
    let char = arr[i].charAt(0);
    arr[i] = arr[i].replace(char, function replace(char){
      return char.toUpperCase();
    });
  }
  return arr.join(' ');
};

export const statusArrToValueFunc=(value,data,key='dictValue',label='description')=>{
  if(!value && value!==0)return '';
  let index=data.findIndex((item)=>item[key]===value||Number(item[key])===value);
  return index>-1?data[index][label]:'';
};

export const startToEndTime=(startTime,endTime)=>{
  let timeDiff = endTime - startTime
  const hour = Math.floor(timeDiff / 3600);
  timeDiff = timeDiff % 3600;
  const minute = Math.floor(timeDiff / 60);
  timeDiff = timeDiff % 60;
  const second = timeDiff;
  const dataContent = typeof(startTime)&&typeof(endTime)==="number"? [hour+'小时', minute+'分', second+'秒'] :'';
  return dataContent
}

export const ismobile=()=>{
  var mobileArry = ["iPhone", "iPad", "Android", "Windows Phone", "BB10; Touch", "BB10; Touch", "PlayBook", "Nokia"];
  var ua = navigator.userAgent;
  var res=mobileArry.filter(function(arr) {
      return ua.indexOf(arr) > 0;
  });
  console.log(res)
  return res.length > 0;
}

export const isWeixing=()=>{
  let ua = navigator.userAgent.toLowerCase();
  let isWeixin = ua.indexOf('micromessenger') != -1;
  return isWeixin
}

function formatZero(num, len) {
  if(String(num).length > len) return num;
  return (Array(len).join(0) + num).slice(-len);
}

export const formatSeconds=(value)=>{
  if(!value && value!==0)return 0;
  var theTime = parseInt(value);// 秒
  var middle= 0;// 分
  var hour= 0;// 小时
  if(theTime > 60) {
    middle= parseInt(theTime/60);
    theTime = parseInt(theTime%60);
    //if(String(theTime).length =1)theTime=(Array(1).join(0) + theTime).slice(-1)
   if(middle> 60) {
      hour= parseInt(middle/60);
      middle= parseInt(middle%60);
      result = ""+parseInt(hour)+":"+result;
    }else{
      var result = ""+parseInt(theTime)+"";
      var result = ""+parseInt(middle)+":"+result; 
    }
  }else{
    var result = "00:"+parseInt(theTime)+"";
  }
  return result;
}