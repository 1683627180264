import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';
import { Document, Page } from 'react-pdf';

import {ismobile,isWeixing} from "../../../shared/utils/common"

import {ispone} from '../../../shared/regExp'
import AbieButton from './kits/button'
import{banners,submited,getPassCode,getState} from '../actions/index'

class Isloads extends Component {
  constructor(props) {
    super(props);
    const methods = ['timeClick','poneChange','submit','ridosed','yzChange',
    'onBlurs','onDocumentLoadSuccess','hidLod','sho','shd','goLuder1'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      stime:true,
      inputValue:'',
      ridosed:true,
      yzValue:'',
      yzBlo:false,
      pass:'',
      showpo:false
    };
  }
  goLuder1(){
    this.props.history.push({pathname: `/`})
  }
  onDocumentLoadSuccess(res){
    console.log(res)
  }
  hidLod(){
    this.setState({
      showpo:false
    })
  }
  sho(){
    this.setState({
      ridosed:false,
    })
  }
  shd(){
    this.setState({
      ridosed:true,
    })
  }

  timeClick(){
  const valueBool=this.state.inputValue
  if(ispone(valueBool)){
    getPassCode(valueBool).then((res)=>{
      if(res.data.ResultInfo.ErrCode===0){
        this.setState({
          pass:res.data.ResultInfo.Data
        })
        if(this.state.times==='获取验证码'||this.state.times==='重新获取'){
           var sta=60
           let pop=this
           let ppts=setInterval(function(){
             sta-=1
             pop.setState({
             times:sta+"s"
             })
             if(sta===0){
               pop.setState({
                 times:'重新获取',
                 stime:true
               })
               clearInterval(ppts)
             }
           },1000);
           this.setState({
             stime:false,
             times:60+"s",
           })
       }
      }else if(res.data.ResultInfo.ErrCode===1){
        Toast.info(res.data.ResultInfo.Message, 3);
      }else{
        this.setState({
          pass:res.data.ResultInfo.Data
        })
      }
    })
    }else{
      Toast.info('请输入正确的手机号', 3);
    }
  }

  poneChange(e){
    e.target.value.length<12&&this.setState({
      inputValue:e.target.value
    })
  }
  yzChange(e){
    e.target.value.length<5&&this.setState({
      yzValue:e.target.value
    })

  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  submit(){
    if(ispone(this.mobile.value)){
      localStorage.removeItem("useData");
      if((this.verificationCode.value.length!=4||this.verificationCode.value!=this.state.pass)){   //to   d o
        Toast.info('请输入正确的验证码', 3)
      }else{
        if(!this.state.ridosed){
          Toast.info("请勾选用户协议", 3)
        }else{
          this.setState({
            showpo:true
          })
          submited(this.mobile.value).then((res)=>{
            this.setState({
              showpo:false
            })
            //1 注册未绑定 2初赛未报名 3 初赛已报名 4 复赛未上传 5 复赛已上传 6 决赛未上传 7 决赛已上传 8
            const {Status}=res.data.ResultInfo.Data
            console.log('res.data.ResultInfo',res.data.ResultInfo)
            localStorage.setItem("useData", JSON.stringify(res.data.ResultInfo.Data));
            console.log('Status:',Status,res)
            getState(res.data.ResultInfo.Data.StudentID,this)
          })
        }
      }
    }else{
      Toast.info('请输入正确的手机号', 3)
    }
  }

  ridosed(){
    this.props.history.push({pathname: `/userAppaly`})
  }

  componentDidMount() {
    // // const {banners} = this.props;
    // banners();
    // localStorage.removeItem("useData");
    // if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    var useData = JSON.parse(localStorage.getItem("useData"));
    console.log('useData',useData)
    if(useData){
      this.props.history.push({pathname: `/`})
    }
    document.title = `登录`;
    if(!ismobile())this.props.history.push({pathname: `/loadPc`});
  };

  render() {
    return (
      <div className="loadbac">
        {this.state.showpo&&<div>
          <div className="ab-modal-mask" onClick={this.hidLod}></div>
          <div className="ab-modal-warp ab-warp">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt="" srcset=""/>
            </div>
					</div>
        </div>}
        <img className="load-logo" src={require('../../../assets/images/logo.png')} alt=""></img>
        <h1>登录爱贝</h1>
        <h3>新用户登录领取演讲秘笈一份</h3>
        <div className="form-data">
          <div>
            <img src={require('../../../assets/images/aname.png')} alt=""/>
            <input onBlur={this.onBlurs} value={this.state.inputValue} onChange={this.poneChange} ref={(input)=>{this.mobile=input}} placeholder="手机号" type="number" />
            <button className={this.state.stime?"":"btn-disab"} onClick={this.timeClick}>{this.state.times}</button>
          </div>
          <div>
            <img className="scods" src={require('../../../assets/images/apassword.png')} alt=""/>
            <input onBlur={this.onBlurs} value={this.state.yzValue} ref={(input)=>{this.verificationCode=input}} onChange={this.yzChange} type="number"  placeholder="输入验证码"/>
          </div>
        </div>
        <div onClick={this.submit}>
          <AbieButton className="load-sd">登录</AbieButton>
        </div>
        <div className="ridoses">
          <div onClick={this.state.ridosed?this.sho:this.shd} className={this.state.ridosed?"ridos":"ridosed"} ></div>我已阅读本届<span><a onClick={this.ridosed} target="_blank" >《用户注册及隐私协议》</a></span>
        </div>
        <div className="gotop" onClick={this.goLuder1}>返回首页</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,islogin}} = state;
  return {bannerResource,islogin}
};

const mapDispatchToProps =  {
  banners
};

export default connect(mapStateToProps, mapDispatchToProps)(Isloads);