import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast,Picker,List,DatePicker} from 'antd-mobile';
import { createForm } from 'rc-form';

import AbieButton from './kits/button'
import{banners,getPoneSearch,connect1,getPro,getVic,coneChild,setOut,getState,getPassCode} from '../actions/index'
import {formatDateTime} from '../../../shared/utils/common'
import {ispone} from '../../../shared/regExp'
import {ismobile,isWeixing} from "../../../shared/utils/common"

class Myselfs extends Component {
  constructor(props) {
    super(props);
    const methods = ['modlHide','goLuder','timeClicks','timeClick',
    'poneChange','submit','ridosed','yzChange','tab1','poneChange1',
    'tab2','tab3','tab4','leanName','submitnot','babyName',
    'poneSeach','connect2','onBlurs','canIn','hidLod'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      stime:true,
      inputValue:'',
      ridosed:false,
      yzValue:'',
      yzBlo:false,
      tabBlo:true, 
      tabBlos:true,
      iName:'',
      resd:[],
      iPass:'',
      stdId:'',
      useData:{},
      show:false,
      babyName:'',
      inputValue1:'',
      pass:'',
      StudentList:[],
      studId:'',
      showpo:false
    };
  }

  hidLod(){
    this.setState({
      showpo:false
    })
  }

  timeClick(){
    const valueBool=this.state.inputValue
    if(ispone(valueBool)){
      getPassCode(valueBool).then((res)=>{
        console.log(res)
        if(res.data.ResultInfo.ErrCode===0){
          this.setState({
            pass:res.data.ResultInfo.Data
          })
          if(this.state.times==='获取验证码'||this.state.times==='重新获取'){
             var sta=60
             let pop=this
             let ppts=setInterval(function(){
               sta-=1
               pop.setState({
               times:sta+"s"
               })
               if(sta===0){
                 pop.setState({
                   times:'重新获取',
                   stime:true
                 })
                 clearInterval(ppts)
               }
             },1000);
             this.setState({
               stime:false,
               times:60+"s",
             })
         }
        }else if(res.data.ResultInfo.ErrCode===1){
          Toast.info(res.data.ResultInfo.Message, 3);
        }else{
          this.setState({
            pass:res.data.ResultInfo.Data
          })
        }
      })
      }else{
        Toast.info('请输入正确的手机号', 3);
      }
    }

  timeClicks(){
    const {getPoneSearch}=this.props
    const {inputValue}=this.state
    if(ispone(inputValue)){
      getPoneSearch(inputValue)
    }else{
      Toast.info('请输入正确的手机号', 3);
    }
  }

  goLuder(){
    this.props.history.push({pathname: `/load`})
    localStorage.removeItem("useData");
  }

  modlHide(){
    this.setState({
      show:false
    })
  }

  canIn(){
    if(ispone(this.mobile.value)){
      if((this.verificationCode.value.length!==4||this.verificationCode.value!==this.state.pass)){   //to   d o
        Toast.info('请输入正确的验证码', 3)
      }else{
          this.setState({
            showpo:true
          })
          coneChild(this.state.studId,this.props.match.params.id).then((res)=>{
            if(res.data.ResultInfo.ErrCode===0){
              this.props.history.push({pathname: `/aplay/${this.state.studId}`,})
            }else{
              Toast.info(res.data.ResultInfo.Message, 3)
            }
            this.setState({
              showpo:false
            })
          }).catch(()=>{
            this.setState({
              showpo:false
            })
          })
      }
    }
  }

  connect2(){
    this.setState({
      show:true,
    })
  }

  submitnot(){
    const {babyName,date,porCode,vieCode,stdId}=this.state
    if(babyName&&date&&porCode&&vieCode&&stdId){
      let subData={
        studentid: stdId,
        studentname: babyName,
        birthday:formatDateTime(date),
        province:porCode.label,
        city:vieCode.label,
        provinceid:porCode.value,
        cityid:vieCode.value,
      }
      setOut(subData).then((res)=>{
        console.log('res111',res.data.ResultInfo.ErrCode)
        if(res.data.ResultInfo.ErrCode===0){
          this.props.history.push({pathname: `/mine/${this.state.stdId}`})
        }else{
          Toast.info(res.data.ResultInfo.Message, 3)
        }
        // this.props.history.push({pathname: `/mine/${this.state.stdId}`})
      })
    }else{
      Toast.info('请填写完成的信息', 3);
    }
  }
  babyName(e){
    e.target.value.length<=15&&this.setState({
      babyName:e.target.value
    })
  }

  poneSeach(e){
    e.target.value.length<12&&this.setState({
      inputValue:e.target.value
    })
  }

  poneChange(e){
    this.setState({
      iPass:e.target.value
    })
  }
  yzChange(e){
    e.target.value.length<5&&this.setState({
      yzValue:e.target.value
    })
  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  leanName(e){
    this.setState({
      iName:e.target.value
    })
  }
  submit(){
    const {iName,iPass,stdId}=this.state
    if(!iName){
      Toast.info('请输入学员账号', 3);
    }else if(!iPass){
      Toast.info('请输入密码', 3);
    }else{
      connect1(iName,stdId,iPass).then((res)=>{
        if(res.data.ResultInfo.ErrCode===0){
          this.props.history.push({pathname: `/aplay/${this.state.stdId}`})
        }else{
          Toast.info(res.data.ResultInfo.Message, 3)
        }
      })
    }
  }
  ridosed(){
    this.setState({
      ridosed:true
    })
  }
  tab1(){
    this.setState({
      tabBlo:true
    })
  }
  tab2(){
    this.setState({
      tabBlo:false
    })
  }
  tab3(){
    this.setState({
      tabBlos:true
    })
  }
  tab4(){
    this.setState({
      tabBlos:false
    })
  }

  poneChange1(e){
    if(e.target.value.length<12)this.setState({
      inputValue1:e.target.value
    })
  }
  
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    const {getPro} = this.props;
    var useData = JSON.parse(localStorage.getItem("useData"));

    console.log(useData.StudentList.length)
    if(useData.StudentList.length>1){
      this.setState({
        inputValue:useData.Mobile,
        StudentList:useData.StudentList,
        tabBlos:false,
      })
    }
    const {StudentID}=useData
    const that=this
    getState(StudentID,that)
    // this.setState({
    //   inputValue:
    // })
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    getPro()
    this.setState({
      stdId:this.props.match.params.id
    })
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  render() {
    const { getFieldProps } = this.props.form;
    const { getProdata,chidList} = this.props  ///to do 搜所以props后的数据,没EIP测试账号
    const { Mobile } = this.state.useData
    console.log('this.props.chidList',chidList,chidList.length)
    const {show}=this.state
    let thats=this
    return (
      <div className="loadbac">
        {this.state.showpo&&<div>
          <div className="ab-modal-mask" onClick={this.hidLod}></div>
          <div className="ab-modal-warp ab-warp">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt="" srcset=""/>
            </div>
					</div>
        </div>}
        {show&&<div>
          <div className="ab-modal-mask" onClick={this.modlHide}></div>
          <div className="ab-modal-warp ab-warps">
                <div className="ab-modal-click" onClick={this.modlHide}></div>
								<div className="ad-modal-cont">
                  <div className="ad-modal-bac">
                      <p style={{textAlign:"center"}}>确认是您孩子的账号？</p>
                      <div className="pone">
                        <input  onBlur={this.onBlurs} value={this.state.inputValue} ref={(input)=>{this.mobile=input}} placeholder="手机号" type="number"/>
                          <button  className={this.state.stime?"":"btn-disab"} onClick={this.timeClick}>{this.state.times}</button>
                      </div>
                      <div className="ipod"><input  onBlur={this.onBlurs} value={this.state.yzValue} ref={(input)=>{this.verificationCode=input}} onChange={this.yzChange} type="number" placeholder="输入验证码"/></div>
                  </div>
                  <div className="fired" onClick={this.canIn} >
                    确定
                  </div>
								</div>
						</div>
        </div>}
        <div className="sef-head">
          <img src="" src={require('../../../assets/images/def_tx.png')} alt=""/>
          <div>
            <p>{Mobile}</p>
            <p>还未绑定学习账号</p>
          </div>
          <div>
            <button className={"btn-out"} onClick={this.goLuder}>登出账号</button>
          </div>
        </div>
        <div className="form-content">
          <div className="tabs">
            <div onClick={this.tab1} className={(!this.state.tabBlo)?"":"tab-curent"}>绑定学习账号</div>
            <div onClick={this.tab2} className={this.state.tabBlo?"":"tab-curent"}>成为爱贝学员</div>
          </div>
          <div className="tab-lit-cont">
            {this.state.tabBlo?<div>
              <div className="tab-tips">就差最后一步啦!</div>
              <div className="lit-taps">
                <div className="lit-tap">
                  <div onClick={this.tab3} className={(this.state.tabBlos)?"tab-lit-curent":""}>通过学号绑定</div>
                  <div onClick={this.tab4} className={(this.state.tabBlos)?"":"tab-lit-curent"}>通过手机号绑定</div>
                </div>
                {this.state.tabBlos?<div>
                  <div className="form-data stem">
                    <label><img style={{ margin: "1.9rem",paddingRight: "1.4rem"}} className="scods" src={require('../../../assets/images/rw.png')} alt=""/></label>
                    <input onBlur={this.onBlurs} onChange={this.leanName}  placeholder="爱贝学习账号" />  
                  </div>
                  <div className="form-data stem" style={{margin:"1.6rem auto"}}>
                    <label htmlFor="male2"><img style={{ margin: "1.9rem",paddingRight: "1.4rem"}} className="scods" src={require('../../../assets/images/pass.png')} alt=""/></label>
                    <input onBlur={this.onBlurs} onChange={this.poneChange} type='password' placeholder="密码" />
                  </div>
                  <AbieButton className="load-sd btms" onClick={this.submit}>绑定</AbieButton>
                  <div className="bot-tips">
                    若您遗失学习账号，请联系所在学习中心老师获取,<br></br>
                    或者使用报班时的手机号登录
                  </div>
                </div>:<div>
                  <div className="form-data stem">
                    <div>
                      <input onBlur={this.onBlurs} onChange={this.poneSeach} value={this.state.inputValue} placeholder="输入合同手机号,搜索要绑定的账号" type="number" />
                      <button className={"btn-seach"} onClick={this.timeClicks}>搜索</button>
                    </div>
                  </div>
                  {chidList.length==0?(this.state.StudentList.length>1?
                    this.state.StudentList.map((item,index)=>{
                    return <div className="searchs">{index===0&&<p>查询结果</p>}
                    <div>
                      <div>
                        <p>姓名：{item.StudentName}</p>
                        <p>中心校区:{item.OrgName}</p>
                      </div>
                      <button className="btn-seach1" onClick={()=>{this.setState({
                        studId:item.StudentCode,
                        show:true,
                        inputValue:item.Mobile
                      })}}>绑定该账号</button>
                    </div></div>
                  })
                  :<div className="bot-tips">
                    若您遗失学习账号，请联系所在学习中心的老师获取
                  </div>):
                  chidList.map((item,index)=>{
                    return <div className="searchs">
                      {index===0&&<p>查询结果</p>}
                      <div>
                        <div>
                          <p>姓名：{item.StudentName}</p>
                          <p>中心校区:{item.OrgName}</p>
                        </div>
                        <button className="btn-seach1" onClick={()=>{
                          this.setState({
                            studId:item.StudentCode,
                            show:true,
                            inputValue:item.Mobile
                          })
                        }}>绑定该账号</button>
                      </div>
                    </div>
                  })
                  }
                </div>}
              </div>
            </div>:<div>
              <div className="tab-tips">走进Speak Out之外的爱贝</div>
              <p>全国440多家线下中心,等你发现:</p>
              <div className="lit-taps">
                  <div className="yb-tips">
                    
                    <div><div></div>高端优质的外教课堂</div><div><div></div>多元丰富的主题活动</div>
                    <div><div></div>权威专业的课程体系</div><div><div></div>更多无数优秀小学伴</div> 
                  </div>
                  <div className="fzds">
                    <div className="form-data stem tios tiosd">
                      <div>
                        <input onBlur={this.onBlurs} value={this.state.babyName} onChange={this.babyName} placeholder="宝贝姓名" />
                      </div>
                    </div>
                    <div className="form-data stem tios">
                      <div>
                         <DatePicker
                          mode="date"
                          title="宝贝生日"
                          extra="宝贝生日"
                          maxDate={new Date(2017, 7, 31, 23, 59, 59)}
                          minDate={new Date(2006, 8, 1, 23, 59, 59)}
                          value={this.state.date}
                          onChange={date => this.setState({ date })}
                        >
                          <List.Item></List.Item>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                  <div className="fzds">
                    <div className="form-data stem tios">
                      <div>
                        <Picker 
                          data={getProdata} 
                          cols={1}
                          extra="省"
                          {...getFieldProps('required1', {
                            onChange(e){
                              getVic(e[0]).then((res)=>{
                                let arryData=[]
                                    res.data.ResultInfo.map((e,index)=>
                                    arryData.push({'value':e.CityID,'label':e.Name})
                                  )
                                thats.setState({
                                  resd:arryData,
                                  porCode:getProdata.filter(function(x){ return x.value===e[0]})[0]
                                })
                              })
                            },
                          })}
                        >
                        <List.Item></List.Item>
                      </Picker>
                      <div className="downIcon">
                        <img src={require('../../../assets/images/down_icon.png')} alt="" srcSet=""/>
                      </div>
                      </div>
                    </div>
                    <div className="form-data stem tios">
                      <div>
                        <Picker 
                          data={this.state.resd} 
                          cols={1} 
                          extra="市"
                          {...getFieldProps('required2', {
                            onChange(e){
                              // this.state.resd.filter(function(x){ return x=e[0]})
                              thats.setState({
                                vieCode:thats.state.resd.filter(function(x){ return x.value===e[0]})[0]
                              })
                            },
                          })}
                        >
                          <List.Item></List.Item>
                        </Picker>
                        <div className="downIcon">
                        <img src={require('../../../assets/images/down_icon.png')} alt=""/>
                      </div>
                      </div>
                    </div>
                  </div>
                  <AbieButton className="load-sd btms" onClick={this.submitnot}>提交</AbieButton>
              </div>
            </div>}
          </div>
        </div>
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,islogin,getProdata,chidList}} = state;
  return {bannerResource,islogin,getProdata,chidList}
};

const mapDispatchToProps = {
  banners,
  getPoneSearch,
  getPro
};

export default createForm()(connect(mapStateToProps, mapDispatchToProps)(Myselfs));