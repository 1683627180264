import React, {Fragment} from 'react';

import './button.scss';

interface propsType {
  children?: React.ReactNode;
  style?: object;
  isLoading?: boolean;
  type?: string;
  isDisabled?: boolean;
  icon?: string;
  size?: string;
  onClick?: Function;
  classNamed?:boolean;
  className:''
}

export default function AbieButton(props: propsType) {

  const {style, isLoading, isDisabled, children,classNamed,className} = props;

  const btnClassNames = `btn-${isDisabled?'erryBtn':props.type || 'default'} btn btn-${props.size || 'default'} ${className}`;

  const handleClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e: any) => {
    const {onClick} = props;
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <Fragment>
      <button
        className={!classNamed?btnClassNames:'por-acive-btn'}
        style={style}
        disabled={isLoading || isDisabled || false}
        onClick={handleClick}
      >
        <div className="por-btn-content">
          {children}
        </div>
      </button>
    </Fragment>
  )
}

