import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import {onlyDate} from '../../../shared/utils/common'

import {ispone} from '../../../shared/regExp'
import{getNewsData} from '../actions/index'
import {ismobile,isWeixing} from "../../../shared/utils/common"

class ToRouter extends Component {

  constructor(props) {
    super(props);
    const methods = ['loadPage'];
    methods.forEach((item) => this[item] = this[item].bind(this));

  }
state = {
    useData:{},
  }
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    document.title = `活动流程`;
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      })
    }
    this.setState({
      stdId:this.props.match.params.id
    })
    this.props.getNewsData(this.props.match.params.id)
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  loadPage(){
    this.props.history.push({pathname: `/load`,})
  }
  render() {
    return (
      <div className="lcs">
        页面跳转中......
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {nesDatas}} = state;
  return {nesDatas}
};

const mapDispatchToProps =  {
  getNewsData
};

export default connect(mapStateToProps, mapDispatchToProps)(ToRouter);