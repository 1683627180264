import React, {Component} from 'react';

import {ismobile,isWeixing} from "../../../shared/utils/common"

class PcTips extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = `提示!`;
    this.props.history.push({pathname: `/loadPc`});
  };

  render() {
    return (
      <div style={{margin: "10rem",textAlign:"center"}}>
        请用手机端打开!
      </div>
    );
  }
}

export default PcTips;