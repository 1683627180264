import React from 'react';
import Shower from './modules/shower/idex.tsx';
import './style/index.sass';

function App() {
  document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 750*16 + 'px';

  (function(doc, win) {
  var docEl = doc.documentElement,
    resizeEvt =
      'orientationchange' in window ? 'orientationchange' : 'resize'

  function recalc() {
    var clientWidth = docEl.clientWidth
    if (!clientWidth) return
    // clientWidth = clientWidth > 750 ? 750 : clientWidth
    docEl.style.fontSize = clientWidth / 750*16.3+ 'px'
  }
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
  })(document, window)

  return (
    <div className="App">
      <Shower/>
    </div>
  );
}

export default App;