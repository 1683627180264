
export const serviceUnitStatus=(text)=>{
  const status={
    '学前组' :'#28C9F5 ',
    '幼儿组' :'#71C410',
    '少儿组' :'#EA0A0A',
    '少年组' :'#14359E',
  };
  return status[text];
};
export const serviceGroup=(text)=>{
  const status={
    '学前组' :'K',
    '幼儿组' :'Pre-K',
    '少儿组' :'P',
    '少年组' :'E',
  };
  return status[text];
};
