export const deleteSpaceAll=/(^\s*)|(\s*$)/g; //去除首尾空格

export const deleteSpaceFirst=/(^\s*)/g; //去除首部空格

export const isLicensePlate=/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/; // 正则验证车牌,验证通过返回true,不通过返回false

export const regPos= /^\d+(\.\d+)?$/; //非负浮点数

export const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数

export const poneNeg = /^1[3456789]\d{9}$/; //最新手机正则