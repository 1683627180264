import React, {Component } from 'react';
import {connect} from 'react-redux';
import { Carousel, WingBlank,Tabs, WhiteSpace,List,Modal,Card} from 'antd-mobile';
import { BrowserRouter as Router} from "react-router-dom";
import { Player, ControlBar,BigPlayButton } from 'video-react';
import {formatDateToUtc} from '../../../shared/utils/common'

import {ismobile,isWeixing,onlyDate} from "../../../shared/utils/common"
import {
  manyMan,
  getNews,
  getState,
  wxFengx,
  showerAser
} from '../actions';
import {Progress,AbieVideo} from './kits' 


const Item = List.Item;

class Showpage extends Component {
  
  constructor(props) {
    super(props);
    const methods = ['testClick','runinSel','showModal','onClose','clickTab','toDetail','getStr',
    'moreList','showTofr','onChange','handleStateChange','handleStateChanges','handleStateChangesd',
    'toTop','handleScroll','newsData','hoide','hoided','moreList1','lopss','lopssd','lopssds'];
    methods.forEach((item) => this[item] = this[item].bind(this));
  }

  state = {
    data: ['http://www.abiechina.com/video/ol.mp4', 'http://www.abiechina.com/video/ol.mp4', 'http://www.abiechina.com/video/ol.mp4'],
    imgHeight: 176,
    modal1: false,
    allpes:[],
    setCont:10,     //--6--------------------后期展开缩放
    useData:{},
    mored:true,
    tops:false,
    show:false,
    conters:'0',
    shower:false,
    showvides:true,
    showvidess:true,
    showvidessd:true,
    paused:true,
    pauseds:true,
    pausedsd:true,
    showMove:true,
    vote:false,
    getPage:2,
    showMovedd:true,
    showMovedds:false,
  }

  newsData(e){

    // const nesd=this.nesd
    // this.props.history.push({
    //   pathname: `/newsData/${nesd}`,
    // });
  }
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      paused: state.isFullscreen
    });
  }

  handleStateChanges(state) {
    // copy player state to this component's state
    this.setState({
      pauseds: state.isFullscreen
    });
  }

  handleStateChangesd(state) {
    // copy player state to this component's state
    this.setState({
      pausedsd: state.isFullscreen
    });
  }

  lopss(){
    this.player.play()
    this.setState({
      showvides:false,
      showMovedds:false
    })
  }

  lopssd(){
    this.players.play()
    this.setState({
      showvidess:false,
    })
  }
  lopssds(){
    this.playersd.play()
    this.setState({
      showvidessd:false,
    })
  }


  hoide(){
    this.setState({
      show:false
    })
  }
  hoided(){
    this.setState({
      shower:false
    })
  }

  handleScroll(event){
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) 
    if(scrollTop>100){this.setState({
      tops:true
    })}
    else{this.setState({
      tops:false
    })}
  }

  toTop(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  moreList(){
    this.setState({
      setCont:10,
      mored:false
    })
  }
  moreList1(){
    this.setState({
      setCont:6,
      mored:true
    })
  }

  testClick(){
    this.props.history.push({
      pathname: `/load`,
    });
  }
  
  runinSel(){
    const {StudentID}=this.state.useData
    const that=this
    getState(StudentID,that)
  }
  toDetail(d){
    // window.location.href="https://speakout.abiechina.com/index1.html?id="+d //正式环境
    //window.location.href="https://speakout.abiechina.com/test/index1.html?id="+d //测试环境
    this.props.history.push({ 
      pathname: `/detail/${d}`,
    });
  }

  showModal (){
    this.props.history.push({
      pathname: `/userPromis`,
    });
  }

  showTofr(){
    localStorage.removeItem("useData");
    localStorage.removeItem("openids");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.setState({
      shower:true 
    })
  }

  clickTab(e,s){
    this.setState({
      mored:true,
      setCont:10,
    })
    const {allPeo}=this.props
    allPeo.map((d,index) =>{
      (e.title===d.GroupName)&&this.setState({
        allpes: d.StudentList
      });
    })
  }
  
  onChange() {
    var videos = document.getElementsByTagName('video');
    for (var j = videos.length - 1; j >= 0; j--) {
      videos[j].pause();
    }
  }                                           
  getStr(){
    if(this.state.getPage>=10){
      this.setState({
        getPage:1
      })
      this.props.manyMan(this.state.getPage);
    }else{
      this.setState({
        getPage:this.state.getPage+1
      })
      this.props.manyMan(this.state.getPage);
    }
  }
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    // let thetime = '2020-06-01 20:45:00';
    // let d=new   Date(Date.parse(thetime .replace(/-/g,"/")));
    // let curDate=new   Date();
    // d <=curDate&&this.setState({vote:false})
    var u = navigator.userAgent, app = navigator.appVersion;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      this.setState({
        showMovedd:true
      })
    }
    if (isIOS) {
      this.setState({
        showMovedds:true
      })
    }
    this.player.subscribeToStateChange(this.handleStateChange);
    this.players.subscribeToStateChange(this.handleStateChanges);
    this.playersd.subscribeToStateChange(this.handleStateChangesd);
    let codeData = {};
    this.setState({
      showMove:true
    })
    window.location.search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => codeData[k] = v);
    // if(codeData.id){
    //   alert('有id'+codeData.id)
    // }
    // alert('没有id')
    localStorage.removeItem("useUrl");
    const {manyMan,getNews} = this.props;
    manyMan("1");
    getNews();
    window.addEventListener('scroll', this.handleScroll, { passive: false })
    wxFengx()
    document.title = `第七届Speak Out英语风采盛典`;
    this.props.history.listen((e) => {
      console.log(e,e.pathname)
    });
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData,
      })
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll, { passive: false })
  }
  render() {
    const {allTilt,news} = this.props;
    const tabs = allTilt
    return (
      <div>
        {this.state.shower&&<div>
          <div className="ab-modal-warp noTrans">
            <div className="ab-modal-mask" onClick={this.hoided}></div>
            <img src={require('../../../assets/images/shower.png')} alt=""/>
					</div>
        </div>}
        {this.state.show&&<div>
          <div className="ab-modal-warp shows">
            <div className="ab-modal-mask" onClick={this.hoide}></div>
            <div className="showTrue showTrued">
              <div>
                <AbieVideo
                  videoUrl={this.state.conters}
                  videoHeight="100%"
                  className='videBox'
                  noBIge={false}
                >
                </AbieVideo>}
              </div>
            </div>
					</div>
        </div>}
        <div className="page-header">
          <div className="po">
                <div className="heade-more">
                    <div className="peples">当前报名人数:<span>4839</span>人</div>
                    <Progress/>
                    <div className="button-gr"> 
                      <Router>
                        <button onClick={this.showModal}><span><img src={require('../../../assets/images/jr.png')} alt=""></img></span>活动日程</button>
                        {
                          JSON.stringify(this.state.useData) !=="{}"?
                          <button onClick={this.runinSel} className="second-btn"><span><img src={require('../../../assets/images/gz.png')} alt=""></img></span>个人中心</button>:
                          <button onClick={this.testClick} className="second-btn"><span><img src={require('../../../assets/images/gz.png')} alt=""></img></span>我要报名</button>
                          }
                      </Router>
                    </div>
                </div>
            </div>
            <div>
                <div className="cild-show cild-show2">
                    <h1>
                      <span><img src={require('../../../assets/images/title.png')} alt=""></img></span>必看!!!
                    </h1>
                </div>
                {/* <div className="showMove"> */}
                {this.state.showMovedds&&<img style={{width:"0px"}} className="jcsod" onClick={this.lopss} src={require('../../../assets/images/star1.png')} alt="" sizes=""/>}
                <Carousel 
                  afterChange={this.onChange}
                  // autoplay={true}
                >
                  
                  <div>
                    {this.state.showMovedd?this.state.showvides&&<img className="jcso1" onClick={this.lopss} src={require('../../../assets/images/star1.png')} alt="" sizes=""/>:
                    <img className="jcso1" onClick={this.lopss} src={require('../../../assets/images/star1.png')} alt="" sizes=""/>}
                    {<Player
                        // playsInline={true}
                        // fluid={true}
                        className={this.state.showvides&&"jcso"}
                        ref={player => {
                          this.player = player;
                        }}
                        src={'https://sp.abiechina.com:553/Upload/PastPeriod/CAM0_000_x000.mp4'}
                      >
                      <BigPlayButton position="center" />
                      <ControlBar autoHide={false} />
                    </Player>}
                  </div>
                  <div>
                  {this.state.showMovedd?this.state.showvidess&&<img className="jcso1" onClick={this.lopssd} src={require('../../../assets/images/defalPro1.png')} alt="" sizes=""/>:
                  <img className="jcso1" onClick={this.lopssd} src={require('../../../assets/images/defalPro1.png')} alt="" sizes=""/>
                  }
                    {<Player
                        // playsInline={true}
                        // fluid={true}
                        className={this.state.showvidess&&"jcso"}
                        ref={player => {
                          this.players = player;
                        }}
                        src={'https://sp.abiechina.com:553/Upload/PastPeriod/nx2.mp4'}
                      >
                      <BigPlayButton position="center" />
                      <ControlBar autoHide={false} />
                    </Player>}
                  </div>
                  <div>
                  {this.state.showMovedd?this.state.showvidessd&&<img className="jcso1" onClick={this.lopssds} src={require('../../../assets/images/defalPro2.png')} alt="" sizes=""/>:
                  <img className="jcso1" onClick={this.lopssds} src={require('../../../assets/images/defalPro2.png')} alt="" sizes=""/>}
                    {<Player
                        // playsInline={true}
                        // fluid={true}
                        className={this.state.showvidessd&&"jcso"}
                        ref={player => {
                          this.playersd = player;
                        }}
                        src={'https://sp.abiechina.com:553/Upload/PastPeriod/yb3.mp4'}
                      >
                      <BigPlayButton position="center" />
                      <ControlBar autoHide={false} />
                    </Player>}
                  </div>
                </Carousel>
                {/* </div> */}
                
                {/* <WingBlank>
                  <Carousel className="space-carousel"
                    frameOverflow="visible"
                    cellSpacing={10}
                    slideWidth={0.8}
                    autoplay
                    infinite
                    dots={false}
                    // beforeChange={(from, to) => 
                    //   console.log(`slide from ${from} to ${to}`)}
                    afterChange={index => this.setState({ slideIndex: index })}
                  >
                    {this.state.data.map((val, index) => (
                      <div
                        onClick={()=>{
                          this.setState({
                            conters:val,
                            show:true
                          })
                        }}
                        key={val}
                        style={{
                          display: 'block',
                          position: 'relative',
                          // top: this.state.slideIndex === index ? -10 : 0,
                          height: this.state.imgHeight,
                          boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <img
                          src={require('../../../assets/images/star1.png')}
                          style={{ width: '100%', verticalAlign: 'top' }}
                          onLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                            this.setState({ imgHeight: 'auto' });
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </WingBlank> */}
            </div>
            <div>
              <div className="cild-show cild-show1">
                  {this.state.vote?<h1>
                    <span><img src={require('../../../assets/images/huor.png')} alt=""></img></span>人气Top
                  </h1>:<h1>
                    <span><img src={require('../../../assets/images/huor.png')} alt=""></img></span>全国Top 100精选
                    <img onClick={this.getStr} className="retro" src={require('../../../assets/images/retro.png')} alt=""></img>
                  </h1>}
              </div>
              <WhiteSpace />
                {allTilt&&allTilt.length!==1&&<Tabs tabs={tabs} initialPage={0} distanceToChangeTab="50" animated={false} onTabClick={this.clickTab} useOnPan={false}></Tabs>}
                <div className='tabContent2'>
                  <div className='tabContent1'>
                    <div className='tabContent'>
                    {this.props.allPeo[0]&&((!(this.state.allpes.length===0)?this.state.allpes:(this.props.allPeo[0].StudentList)).map((d,index) =>   //可以设置默认的人气组
                      index<this.state.setCont&&<div key={index} onClick={()=>this.toDetail(d.WorksID)}>
                          {/* {d.CoverUrl?<img src={require(d.CoverUrl)}></img>:<img src={require('../../../assets/images/cildr.png')}></img>} */}
                          <div className="urlCover">
                            {d.CoverUrl?<div className="imgPos" style={{backgroundImage:"url("+d.CoverUrl+")"}}><img className="jcso1img" src={require('../../../assets/images/pales.png')} alt="" sizes=""/></div>:<img src={require('../../../assets/images/cildr.png')}></img>}
                          </div>
                          <div className="addrs">
                            {d.OrgName}
                          </div>
                          <div className="bot-cont">
                            <div className="bot-name"><img className="retro" src={require('../../../assets/images/left_rol.png')} alt=""></img>{d.StudentName}</div>
                            {d.Type!==3?(this.state.vote?<div className="bot-num">点赞量:<span>{d.VoteNum}</span></div>:<div className="bot-num">更多详情</div>):<div className="bot-num">{d.Birthday}</div>}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <img onClick={this.getStr} style={{width:"8rem",marginTop:"2rem",height:"2rem"}} src={require('../../../assets/images/retro1.png')} alt=""></img>
                </div>
              <WhiteSpace />
              {/* {this.state.mored?<div className="more">
                <img onClick={this.moreList} src={require('../../../assets/images/more-26.png')} alt=""></img>
              </div>:<div className="more">
                <img onClick={this.moreList1} src={require('../../../assets/images/more-26.png')} alt=""></img>
              </div>} */}
              <div className="cild-show">
                  <h1>
                      <span><img src={require('../../../assets/images/news.png')} alt=""></img></span>活动新闻
                  </h1>
                  <div>
                    <List className="my-list">
                      {
                        news.map((tem,index)=> <Item key={index} align="top" wrap  onClick={() => {
                          console.log(tem)
                          if(tem.NoticeType==="1"){
                             window.location.href = tem.NoticeContent
                            console.log('999',tem.NoticeContent)
                          }else{
                          this.props.history.push({
                            pathname: `/newsData/${tem.NoticeID}`,
                          });}
                        }
                        } extra={onlyDate(tem.ModifyTime)}>{tem.NoticeTitle}</Item>)
                      }
                    </List>
                  </div>
              </div>
              <div className="button-gr button-gr1">
                <button onClick={this.showTofr} className="bto-btn"><span><img src={require('../../../assets/images/bigfr.png')} alt=""></img></span>分享到朋友圈</button>
              </div>
              <div className="bots">
                <div>
                  <div>
                    <img src={require('../../../assets/images/xzs.png')} alt=""></img>
                  </div>
                  <div>
                    <img src={require('../../../assets/images/gzh.png')} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
        </div>
        {this.state.tops&&<div onClick={this.toTop} className="totps">
          <img src={require('../../../assets/images/to_top.png')} alt="" srcSet=""/>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {allPeo,allTilt,islogin,news}} = state;
  return {allPeo,allTilt,islogin,news}
};

const mapDispatchToProps = {
  manyMan,
  getNews
};

export default connect(mapStateToProps, mapDispatchToProps)(Showpage);