import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import {onlyDate} from '../../../shared/utils/common'

import {ispone} from '../../../shared/regExp'
import{getNewsData} from '../actions/index'
import {ismobile,isWeixing} from "../../../shared/utils/common"

class NewsData extends Component {
  
  constructor(props) {
    super(props);
    const methods = ['timeClick'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      stdId:'',
    };
  }

  timeClick(){
     if(this.state.times==='获取验证码'){
       if(ispone(this.state.inputValue)){
        var sta=60
        let pop=this
        let ppts=setInterval(function(){
          sta-=1
          pop.setState({
          times:sta+"s"
          })
          if(sta===0){
            pop.setState({
              times:'获取验证码',
              stime:true
            })
            clearInterval(ppts)
          }
        },1000);
        this.setState({
          stime:false,
          times:60+"s",
        })
       }else{
        Toast.info('请输入正确的手机号码', 3);
       }
    }
  }

  
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    document.title = `新闻详情`;
    this.setState({
      stdId:this.props.match.params.id
    })
    this.props.getNewsData(this.props.match.params.id)
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  render() {
    const {NoticeTitle,NoticeContent,CreateTime}=this.props.nesDatas
    return (
      <div className="esNes loadbac12 details">
        <WingBlank size="lg">
          <WhiteSpace size="lg" />
          <Card>
            <Card.Body>
              <div className="namesf" >{NoticeTitle}</div>
              <img src={require('../../../assets/images/newtop.png')} alt="" srcset=""/>
              <div dangerouslySetInnerHTML={{__html: unescape(NoticeContent)}}></div>
              <img src={require('../../../assets/images/newbtm.png')} alt="" srcset=""/>
            </Card.Body>
            <Card.Footer extra={onlyDate(CreateTime)} />
          </Card>
          <WhiteSpace size="lg" />
        </WingBlank>
        <div className="self-bot1">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {nesDatas}} = state;
  return {nesDatas}
};

const mapDispatchToProps =  {
  getNewsData
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsData);