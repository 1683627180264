import React from 'react';

import {formatDateToUtc} from '../../../../../shared/utils/common';
import './progress.less'

interface propsType {
  children?: React.ReactNode;
  style?: object;
  type?: string;
  isDisabled?: boolean;
  icon?: string;
  size?: string;
  onClick?: Function;
  classNamed?:boolean;
  className:'';
}

let crentTime = formatDateToUtc(new Date())
let varryTime=["2020-04-11T00:00:00", "2020-05-11T00:00:00", "2020-05-24T00:00:00", "2020-06-01T00:00:00","2020-07-04T00:00:00"]
var ws=0
const value = varryTime.map((d,index) => 
  crentTime>formatDateToUtc(d)?<li key={index} className= "currnets">{ws++?'':''}</li>:<li key={index}></li>
);
const cales=ws/5
const poped=(ws/6)
export default function AbieButton(props: propsType) {
  const {className} = props;
  return (
    <div className={className}>
      <div className="grees">
        <div className="gr-do" style={{width:poped*40+"rem"}}>
          <ul className="points">
            {value}
          </ul>
        </div>
      </div>
      <div className="down-tips">
          <div style={{width:cales*40+"rem"}}>
              <p>
                  {cales===0?"":"( 进行中 )"}
              </p>
          </div>
      </div>
      <div className="ingres">
          <div>全国启动</div>
          <div>报名截止</div>
          <div>视频收集</div>
          <div>名单揭晓</div>
          <div>线上盛典</div>
      </div>
    </div>
  );
}
