import React, {Component} from 'react';

class Iswx extends Component {

  componentDidMount() {
    document.title = `提示!`;
  };

  render() {
    return (
      <div style={{margin: "10rem",textAlign:"center"}}>
        "请用微信打开!"
      </div>
    );
  }
}

export default Iswx;