import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button,Modal,Toast,WhiteSpace, WingBlank} from 'antd-mobile';
import { Player, ControlBar,BigPlayButton } from 'video-react';

import {ismobile,isWeixing} from "../../../shared/utils/common"
import{getDtail,addVote,showerAser,getOpneId} from '../actions/index'
import{serviceUnitStatus} from "../../../shared/utils/status"

class Detail extends Component {
  constructor(props) {
    super(props);
    const methods = ['poneChange','btnState','topLoad','showTofr','hoided','hoid','lopss','btnStates'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      stime:true,
      inputValue:'',
      yzValue:'',
      yzBlo:false,
      fullscan:false,
      heightclient:'',
      useData:{},
      shower:false,
      showeree:false,
      videosh:true,
      showvides:true,
      code:'',
      showNoBtn:true
    };
  }
  lopss(){
    this.player.play()
    this.setState({
      showvides:false
    })
  }
  topLoad=()=>{
    this.props.history.push({pathname: `/`,})
  }
  showTofr(){
    this.setState({
      shower:true,
      showeree:false,
      videosh:false
    })
  }
  hoided(){
    this.setState({
      shower:false,
      videosh:true
    })
  }
  hoid(){
    this.setState({
      showeree:false
    })
  }
  btnStates(){
    Toast.info('已经为该作品投过票', 3)
  }
  btnState=()=>{
    
    const {getDtail,detail}=this.props
    const id=detail.WorksID
    let opd=this.state.idsd
    if(this.state.useApId){
      if(this.state.useApId.length===28){
        addVote(id,this.state.useApId).then((e)=>{
          if(e.data.ResultInfo.ErrCode===0){
            getDtail(id)
            this.setState({
              showeree:true,
              showNoBtn:false
            })
          }else if(e.data.ResultInfo.ErrCode===3){
            Toast.info('已经为该作品投过票', 3)
            this.setState({
              showNoBtn:false
            })
          }else{
            if(e.data.ResultInfo.Message==="用户未授权"||e.data.ResultInfo.Message==="授权信息错误"){
              setTimeout(function(){window.location.href="https://speakout.abiechina.com/index1.html?id="+opd},3000);//正式
              // setTimeout(function(){window.location.href="http://it-test.abiechina.com:9005/index1.html?id="+opd},3000);//测试
            }else{
              Toast.info(e.data.ResultInfo.Message, 3)
            }
          }
        }).catch(()=>{
          Toast.info('微信授权中...', 3)
          let opd=this.state.idsd
          setTimeout(function(){window.location.href="https://speakout.abiechina.com/index1.html?id="+opd},3000);//正式
          // setTimeout(function(){window.location.href="http://it-test.abiechina.com:9005/index1.html?id="+opd},3000);//测试
        })
      }else{
        // window.location.href = 'http://it-test.abiechina.com:9005/index1.html?id='+this.state.idsd  //测试
        window.location.href="https://speakout.abiechina.com/index1.html?id="+this.state.idsd    //正式
      }
    }else{
      // window.location.href = 'http://it-test.abiechina.com:9005/index1.html?id='+this.state.idsd  //测试
      window.location.href="https://speakout.abiechina.com/index1.html?id="+this.state.idsd    //正式
    }
  }
  poneChange(){
    this.setState({
      fullscan:true
    })
  }
  componentWillMount(){
    document.title = `页面正在加载中...`;
  }
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    let index=window.location.hash.lastIndexOf("\=");
    let obj=window.location.hash.substring(index+1,window.location.hash.length);
    this.setState({
      code:obj,
    })
    
    var useApId = localStorage.getItem("openids");
    const id=this.props.match.params.id
    if(!useApId||useApId.length!==28){getOpneId(obj).then((res)=>{
        if(res.data.ResultInfo.ErrCode===2){
          window.location.href = 'https://speakout.abiechina.com/index1.html?id='+id //正式
          // window.location.href = 'http://it-test.abiechina.com:9005/index1.html?id='+id  //测试
        }else{
          localStorage.setItem("openids", JSON.parse(res.data.ResultInfo.Data).openid);
          this.setState({
            useApId:JSON.parse(res.data.ResultInfo.Data).openid
          })
        }
        }).catch(()=>{
       
        })
      }else{
        this.setState({
          useApId
        })
      }
    
    const {getDtail}=this.props
    
    this.setState({
      idsd:id
    })
    getDtail(id,useApId)
    showerAser(id,this)
    this.setState({
      heightclient:window.innerheight
    })
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData,
        useApId
      })
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };

  componentDidUpdate(){
    document.title = this.props.detail.WorksName?this.props.detail.WorksName:'正在加载...';
  }

  render() {
    const {detail}=this.props
    return (
      <div style={{"height":this.state.heightclient}} className='details'>
        <div>
          {this.state.shower&&<div>
            <div className="ab-modal-warp noTrans">
              <div className="ab-modal-mask" onClick={this.hoided}></div>
              <img src={require('../../../assets/images/shower.png')}/>
            </div>
          </div>}
          {this.state.showeree&&<div>
            <div className="ab-modal-warp">
              <div className="ab-modal-mask" onClick={this.hoid}></div>
              <div className="addUnm">
                <img src={require('../../../assets/images/suces.png')}/>
                <button onClick={this.showTofr}></button>
              </div>
            </div>
          </div>}
          <div className={this.state.fullscan?"medioed":"medio"}>
            <div className="lohes">
              <img src={require('../../../assets/images/logoe.png')} alt=""/>
            </div>
            {this.state.showvides&&<div className='jcso1 detaPro' onClick={this.lopss} style={{backgroundImage:"url("+detail.CoverUrl+")"}}><img className="jcso1img" src={require('../../../assets/images/pales.png')} alt="" sizes=""/></div>}
            {<Player
              playsInline={true}
              fluid={true}
              ref={player => {
                this.player = player;
              }}
              src={detail.NetUrl}
              className={this.state.showvides&&"jcso"}
            >
            <BigPlayButton position="center" />
            <ControlBar autoHide={false} />
          </Player>} 
          </div>
          <div className="detail-head">
            <img src="" src={require('../../../assets/images/def_tx.png')} alt=""/>
            <div>
              <p>{detail.StudentName}</p>
              <h1>
                <button style={{backgroundColor:serviceUnitStatus(detail.WorksGroup)}}>{detail.WorksGroup?detail.WorksGroup:"未分组"}</button>
                <button style={{backgroundColor:'#FAC42C'}}>{detail.OrgName?detail.OrgName:"暂无数据"}</button>
              </h1>
            </div>
          </div>
          <div className="desc">
          {detail.Type===3?<div className="detalContes">
            <div className="spoOUc1">
              <div>
                {detail.Remark}
              </div>
            </div>
            <div className="spoOUc"></div>
            <div>
              <img src={require('../../../assets/images/xxiong.png')} alt="" />
            </div>
            </div>:<p>
              {detail.WorksDescribe&&detail.WorksDescribe!=="null"?detail.WorksDescribe:'暂无描述'}
            </p>}
          </div>
          {detail.OpenVote?(detail.Type!==3&&<div className="pao-tips">
            点赞数:<span>{detail.VoteNum}</span>
          </div>):
          <div className="songs">
            <img src={require('../../../assets/images/ds-25.png')} alt="" />
          </div> }
          <div className="showered">
            {/* <AbieButton className="load-sd load-fx" ><img src="" src={require('../../../assets/images/fx.png')} alt=""/>分享到朋友圈</AbieButton> */}
            {detail.Type!==3&&detail.OpenVote&&<WingBlank size="lg">
              <WhiteSpace size="lg" />
              {detail.IsVote||(!this.state.showNoBtn)?<Button 
                className={"load-sd greyBtn"}
                // onClick={this.btnState}
              ><div style={{"lineHeight":"3rem","marginTop": "0.4rem"}}>
                <img src="" src={require('../../../assets/images/dz.png')} alt=""/>您已为TA点赞</div>
                </Button>:<Button 
                className="load-sd"
                onClick={this.btnState}
              ><div style={{"lineHeight":"3rem","marginTop": "0.4rem"}}>
                <img src="" src={require('../../../assets/images/dz.png')} alt=""/>为TA点赞</div>
                </Button>}
              <WhiteSpace size="lg" />
            </WingBlank>}
          </div>
          <div onClick={this.topLoad} className="totps">
            <img src={require('../../../assets/images/goIndex.png')} alt="" srcSet=""/>
          </div>
        </div>
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {detail}} = state;
  return {detail}
};

const mapDispatchToProps =  {
  getDtail
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);