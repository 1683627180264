import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import { Toast,DatePicker,List} from 'antd-mobile';

import {ispone} from '../../../shared/regExp'
import {formatDateTime,formatDateToUtc} from '../../../shared/utils/common'
import AbieButton from './kits/button'
import {ismobile,isWeixing} from "../../../shared/utils/common"
import{banners,aplayIn,getState,getStated} from '../actions/index'
class Aplay extends Component {
  constructor(props) {
    super(props);
    const methods = ['timeClick','aplay','canIn','modlHide','goLuder','goLuder1','closeMo','hoided'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      suc:true,
      show:false,
      date:'',
      stdId:'',
      useData:{},
      shower:false,
      shwoPage:false
    };
  }

  aplay(){
    this.setState({
      show:true,
    })
  }

  hoided(){
    this.setState({
      shower:false,
    })
  }

  closeMo(){
    this.setState({
      shower:true,
    })
  }

  goLuder(){
    this.props.history.push({pathname: `/`})
    localStorage.removeItem("useData");
  }
  
  goLuder1(){
    this.props.history.push({pathname: `/`})
  }

  modlHide(){
    this.setState({
      show:false,
    })
  }

  canIn(){
    const {stdId,date}=this.state
    if(date){
      aplayIn(this.props.match.params.id,formatDateTime(date)).then((res)=>{
        console.log('res',res)
        if(res.data.ResultInfo.ErrCode===0){
          this.setState({
          suc:false,
          show:false,
          })
        }else{
          Toast.info(res.data.ResultInfo.Message, 3);
        }
      })
    }else{
      Toast.info('请选择宝宝生日', 3);
    }
    
  }

  timeClick(){
     if(this.state.times==='获取验证码'){
       if(ispone(this.state.inputValue)){
        var sta=60
        let pop=this
        let ppts=setInterval(function(){
          sta-=1
          pop.setState({
          times:sta+"s"
          })
          if(sta===0){
            pop.setState({
              times:'获取验证码',
              stime:true
            })
            clearInterval(ppts)
          }
        },1000);
        this.setState({
          stime:false,
          times:60+"s",
        })
       }else{
        Toast.info('请输入正确的手机号码', 3);
       }
    }
  }

  
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    var useData = JSON.parse(localStorage.getItem("useData"));
    const {StudentID}=useData
    this.setState({
      clientHeight:document.documentElement.clientHeight
    })
    const that=this
    this.props.getStated(StudentID,that)
    getState(StudentID,that)

    console.log(useData)
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    this.setState({
      stdId:this.props.match.params.id
    })
    if(!ismobile())this.props.history.push({pathname: `/talls`,});

    let crentTime = formatDateToUtc(new Date())
    let varryTime="2020-05-11T00:00:00"
    console.log(crentTime>formatDateToUtc(varryTime),crentTime,formatDateToUtc(varryTime))
    crentTime>formatDateToUtc(varryTime)&&this.setState({
      shwoPage:true
    })
  };
  render() {
    const {show} =this.state
   
    return (
      <div className="loadbac details nodta diso" style={{height:this.state.clientHeight}}>
        {this.state.shower&&<div>
          <div className="ab-modal-warp noTrans">
            <div className="ab-modal-mask" onClick={this.hoided}></div>
            <img src={require('../../../assets/images/shower.png')}/>
					</div>
        </div>}
        {show&&<div>
          <div className="ab-modal-mask" onClick={this.modlHide}></div>
          <div className="ab-modal-warp">
                <div className="ab-modal-click" onClick={this.modlHide}></div>
								<div className="ad-modal-cont">
                  <div className="ad-modal-bac">
                      <p>孩子出生于：</p>
                      <DatePicker
                        mode="date"
                        title="宝宝生日"
                        extra="宝宝生日"
                        maxDate={new Date(2017, 7, 31, 23, 59, 59)}
                        minDate={new Date(2006, 8, 1, 23, 59, 59)}
                        value={this.state.date}
                        onChange={date => this.setState({ date })}
                      >
                        <List.Item></List.Item>
                      </DatePicker>
                      <div className="fired" onClick={this.canIn}>
                        确定
                      </div>
                  </div>
								</div>
						</div>
        </div>}
        <div className="sef-head">
          <img src="" src={require('../../../assets/images/def_tx.png')} alt=""/>
          <div>
            <p>{this.props.types.Student&&this.props.types.Student.StudentName}</p>
            <p>{this.props.types.Student&&this.props.types.Student.OrgName}</p>
          </div>
          <div>
            <button className={"btn-out"} onClick={this.goLuder}>登出账号</button>
          </div>
        </div>
        {this.props.types.Student&&this.props.types.Status===2&&this.state.suc?<div className="form-content applysp">
          <div className="tab-lit-cont apply ">
            <div>
                <div className="lit-taps">
                <div className="join-top">我们不再狭隘得定义优秀<br></br>而是比起定义,我们更想去发现。<br></br>秀出每一个优秀的自己!</div>
               {this.state.shwoPage?<AbieButton className="load-sb load-fx unUp" >报名已截至</AbieButton>:<AbieButton className="load-sb load-fx" onClick={this.aplay}>我要报名</AbieButton>}
                <img src={require('../../../assets/images/jion.png')} alt="" srcSet=""/>
              </div>
              {/* <div className="ap-kf">
                  <p>活动客服电话: 400 088 1298</p>
              </div> */}
            </div>
          </div>
          <button className="btn-out1" onClick={this.goLuder1}>返回首页</button>
        </div>:<div>
                {this.state.shwoPage?<img className="lit-tapimg" src={this.props.types.Student&&require('../../../assets/images/no_repeat.png')} alt=""></img>:
                  <img className="lit-tapimg" src={this.props.types.Student&&require('../../../assets/images/aplay-25.png')} alt=""></img>
                }
            </div>}
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,types}} = state;
  return {bannerResource,types}
};

const mapDispatchToProps =  {
  banners,
  getStated
};

export default connect(mapStateToProps, mapDispatchToProps)(Aplay);