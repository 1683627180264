import {Toast} from 'antd-mobile';
import axios from 'axios';
import wx from 'weixin-js-sdk';
// import wx from 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js'

import {
  BANNERS,
  GET_NUM,
  GET_PEO,
  GET_NEWS,
  GET_DETAILE,
  GET_PRO,
  GET_CILDLIST,
  GET_NEWSDATA,
  GET_TYPES
} from '../constants';
import {onlyDate} from '../../../shared/utils/common'

import OSS from 'ali-oss'

const Token="Token=6491d6e8-d15f-4d87-9f4e-f5cdeeed78e3"
const Tokens="6491d6e8-d15f-4d87-9f4e-f5cdeeed78e3"

// const baseUrl="http://erp.abiechina.com:8088/"

 const baseUrlUol="https://sp.abiechina.com:553/"   
 const baseUrl="https://mk-test.abiechina.com/"                           //生产测试环境接口  读数据
const urlLink="https://speakout.abiechina.com/#/detail/"                  //生产环境跳转路径
const baseUrld="https://speakout.abiechina.com/index3.html"
const baseUrls="https://speakout.abiechina.com/"

// const baseUrl="https://sp.abiechina.com:553/"                            //生产环境接口
// const urlLink="https://speakout.abiechina.com/#/detail/"                  //生产环境跳转路径
// const baseUrld="https://speakout.abiechina.com/index3.html"
// const baseUrls="https://speakout.abiechina.com/"

// const baseUrl="http://172.18.11.229:8005" //jeff本地

// const baseUrlUol="http://it-test.abiechina.com:9004/"
// const baseUrl="http://it-test.abiechina.com:9004/"                          //测试环境接口
// const baseUrld="http://it-test.abiechina.com:9005/index3.html"              //测试跳转1
// const baseUrls="http://it-test.abiechina.com:9005/"
// const urlLink="http://it-test.abiechina.com:9005/#/detail/"                 //测试环境跳转路径

export const banners  = () => dispatch => {
    axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/PopularityStudent?'+Token)
    .then(function (response) {
      dispatch({
        type: BANNERS,
        payload: response.data
      })
      
    }).catch(function(){

    })
};
export const getNum = () =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/GetSignUpNum?'+Token)
    .then(function (response) {
      dispatch({
        type: GET_NUM,
        payload: response.data.ResultInfo.Data
      })
      return true
    }).catch(function(){
    })
}

export const manyMan = (pageindex) =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/PopularityStudent?pageindex='+pageindex+"&"+Token)
    .then(function (response) {
      let dataAry=[];
      response.data.ResultInfo.Data.map((d,index) =>{
        dataAry.push({"title":d.GroupName})
      })
      dispatch({
        type: GET_PEO,
        payload: response.data.ResultInfo.Data,
        payloads: dataAry
      })
    }).catch(function(){
    })
}

export const getNews = () =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/NoticeList?'+Token)
    .then(function (response) {
      dispatch({
        type: GET_NEWS,
        payload: response.data.ResultInfo.Data,
      })
      return true
    }).catch(function(){
    })
}


export const getDtail = (id,useApId) =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/WorksInfo?worksid='+id+'&openid='+useApId+"&"+Token)
    .then(function (response) {
      console.log('response',response)
      dispatch({
        type: GET_DETAILE,
        payload: response.data.ResultInfo.Data,
      })
      return true
    }).catch(function(){
    })
}

export const submited = (pone)=>{
  return axios.post(baseUrl+'/Api/SpeakOut/SpeakOut_Common/StudentLogin?mobile='+pone+"&"+Token)
}

export const getOpneId = (code)=>{
  return axios.post(baseUrl+'/Api/SpeakOut/SpeakOut_Common/WX_UserInfo?code='+code+"&"+Token)
}

export const getPassCode = (td) => {
  return axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/VerificationCode?mobile='+td+"&"+Token)
}

export const connect1 = (p,c,d) => {
  return axios.get(baseUrl+`/Api/SpeakOut/SpeakOut_Common/BindStudent?studentcode=${p}&studentid=${c}&pwd=${d}&${Token}`)
}

export const edictTex = (p,c,d) => {
  return axios.get(baseUrl+`/Api/SpeakOut/SpeakOut_Common/StudentUpdateWorks?WorksID=${p}&title=${c}&describe=${d}&${Token}`)
}

export const getState = (c,that)=> {
  //学员状态 1 注册未绑定 2初赛未报名 3 初赛已报名 4 复赛未上传 5 复赛已上传 6 决赛未上传 7 决赛已上传8潜在学员
  return axios.get(baseUrl+`/Api/SpeakOut/SpeakOut_Common/GetWorksStatus?studentid=${c}&${Token}`).then((res)=>{  
    console.log('res',res)
    const {Status,OpenRematch,Works,OpenFinals,OpenVote}=res.data.ResultInfo.Data
    console.log('00200',res.data.ResultInfo.Data)
    if(OpenRematch){
      switch (Status) {
        case 1:
          return that.props.history.push({pathname: `/mine/${c}`,})
        case 0: 
          return that.props.history.push({pathname: `/myself/${c}`,})
        case 2:
          return that.props.history.push({pathname: `/aplay/${c}`,})
        case 3:
          return that.props.history.push({pathname: `/aplay/${c}`,})
        case 4:
          return that.props.history.push({pathname: `/uplode/${c}`,})
        case 5:
          return that.props.history.push({pathname: `/mydetail/${Works.WorksID}`,})
        case 6:
          return that.props.history.push({pathname: `/mydetail/${Works.WorksID}`,})
        case 7:
          return that.props.history.push({pathname: `/myself/${c}`,})
        default:
          return ;
      }
    }else{
      switch (Status) {
        case 1:
          return that.props.history.push({pathname: `/mine/${c}`,})
        case 0: 
          return that.props.history.push({pathname: `/myself/${c}`,})
        case 2:
          return that.props.history.push({pathname: `/aplay/${c}`,})
        case 3:
          return that.props.history.push({pathname: `/aplay/${c}`,})
        case 4:
          return that.props.history.push({pathname: `/uplode/${c}`,})
        case 5:
          return that.props.history.push({pathname: `/mydetail/${Works.WorksID}`,})
        case 6:
          return that.props.history.push({pathname: `/mydetail/${Works.WorksID}`,})
        case 7:
          return that.props.history.push({pathname: `/myself/${c}`,})
        default:
          return ;
      }
    }
    
  })
}
export const getStated = (c,that)=>dispatch => {
  axios.get(baseUrl+`/Api/SpeakOut/SpeakOut_Common/GetWorksStatus?studentid=${c}&${Token}`).then((res)=>{
    console.log('ressss',res)
    dispatch({
      type: GET_TYPES,
      payload: res.data.ResultInfo.Data,
    })
  })
}

export const getNewsData= (d) =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/GetNoticeDetail?id='+d+"&"+Token)
    .then(function (response) {
      dispatch({
        type: GET_NEWSDATA,
        payload: response.data.ResultInfo.Data,
      })
    }).catch(function(){
  })
}

export const getPoneSearch= (id) =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/GetStudentMobile?mobile='+id+"&"+Token)
    .then(function (response) {
      if(response.data.ResultInfo.ErrCode===0){
        Toast.info("查询成功", 3)
        dispatch({
          type: GET_CILDLIST,
          payload: response.data.ResultInfo.Data,
        })
        console.log('response.data.ResultInfo.Data',response.data.ResultInfo.Data)
      }else if(response.data.ResultInfo.ErrCode===1){
        Toast.info('该手机没有查找到对应学习账号,换个手机号试试?', 3)
        dispatch({
          type: GET_CILDLIST,
          payload: [],
        })
      }else{
        Toast.info(response.data.ResultInfo.Message, 3)
        dispatch({
          type: GET_CILDLIST,
          payload: [],
        })
      }
    }).catch(function(){
  })
}
      

export const getPro= () =>dispatch => {
  axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/GetProvinceList?'+Token)
    .then(function (response) {
      let arryData=[]
      response.data.ResultInfo.map((e,index)=>
        arryData.push({'value':e.ProID,'label':e.Name})
      )
      dispatch({
        type: GET_PRO,
        payload: arryData,
      })
      return true
    }).catch(function(){
  })
}

export const getVic= (id) => {
  return axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/GetCityList?ProvinceID='+id+"&"+Token)
}

export const coneChild= (d,c) => {
  return axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/BindStudentMobile?studentcode='+d+'&studentid='+c+"&"+Token)
}

export const aplayIn= (d,c) => {
  return axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/StudentSignUp?studentid='+d+'&birthday='+c+"&"+Token)
}

// export const reloads= (file,worksid) => {
//   return axios.post(baseUrlUol+'/Api/SpeakOut/SpeakOut_Common/WorksRepeatUpload?worksid='+worksid+'&'+Token,file,{
//     headers: {'Content-Type': 'multipart/form-data'}
//   })
// }

export const reloads= (file,id) => {
  let client = new OSS({
    region: "oss-cn-hangzhou", 
    accessKeyId: "LTAI4GBKJYSp9dG1otK5QFYS",
    accessKeySecret: "1KcF2TdLTD9m35G2VfqUekOrdQ5unQ",
    bucket: "abie" 
  });
  var suffix = file[0].name.substr(file[0].name.indexOf(".")).toLowerCase(); // 获取文件后缀名
  var filename = new Date().getTime() + suffix; // 为文件命名
  console.log('filename',filename)

  return client.multipartUpload("speakout/"+onlyDate(new Date().getTime())+"/" + filename, file[0]).then(function (result){
      var url = result.res.requestUrls[0];
      var length = url.lastIndexOf('?');
      var imgUrl = length>0?url.substr(0,length):url; // 文件最终路径
      let imgUrlTo=imgUrl.toLowerCase().replace('http://','https://')
      return axios.get(baseUrl+'Api/SpeakOut/SpeakOut_Common/WorksRepeatUpload?videourl='+imgUrlTo+"&worksid="+id+"&"+Token)
    }).catch(function (err) {
  });

  // let files = file[0];
  // let storeAs = 'upload-file';
  // console.log(files.name + ' => ' + storeAs);
  // // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向`sts-server`发送请求
  // OSS.urllib.request("http://your_sts_server/", {method: 'GET'}, (err, response) => {

  //     let client = new OSS({
  //       region: "oss-cn-hangzhou", 
  //   accessKeyId: "LTAI4GBKJYSp9dG1otK5QFYS",
  //   accessKeySecret: "1KcF2TdLTD9m35G2VfqUekOrdQ5unQ",
  //   bucket: "abie"
  //     });
  //     //storeAs表示上传的object name , file表示上传的文件
  //     client.multipartUpload(storeAs, files).then(function (result) {
  //       console.log(result);
  //     }).catch(function (err) {
  //       console.log(err);
  //     });
  //   });

  // var client = new OSS.Wrapper({
  //   region: "oss-cn-hangzhou", 
  //   accessKeyId: "LTAI4GBKJYSp9dG1otK5QFYS",
  //   accessKeySecret: "1KcF2TdLTD9m35G2VfqUekOrdQ5unQ",
  //   bucket: "abie" 
  // });
  // // ----步骤2-------------------------------------------------
  // var suffix = file[0].name.substr(file[0].name.indexOf(".")); // 获取文件后缀名
  // var filename = new Date().getTime() + suffix; // 为文件命名
  // client.multipartUpload("speakput/"+onlyDate(new Date().getTime())+"/" + filename, file[0]).then(function (result){
  //   console.log('result:',result)
  //     var url = result.res.requestUrls[0];
  //     var length = url.lastIndexOf('?');
  //     var imgUrl = length>0?url.substr(0,length):url; // 文件最终路径
  //     console.log(imgUrl);
  // }).catch(function (err) {
  //     console.log(err);
  // });
  // // return axios.post(baseUrlUol+'/Api/SpeakOut/SpeakOut_Common/WorksRepeatUpload?'+Token,file,{
  // //   headers: {'Content-Type': 'multipart/form-data'}
  // // })
}

export const addVote=(d,c)=> {
  return axios.get(baseUrl+'/Api/SpeakOut/SpeakOut_Common/StudentVote?worksid='+d+'&openid='+c+"&"+Token)
}

export const setOut= (data) => {
  const {studentid,studentname,birthday,province,city,provinceid,cityid}=data
  return  axios.get(baseUrl+`/Api/SpeakOut/SpeakOut_Common/Register?studentid=${studentid}&studentname=${studentname}&birthday=${birthday}&province=${province}&city=${city}&provinceid=${provinceid}&cityid=${cityid}&Token=${Tokens}`)
}
export const saveInformationImage = (url,id,til,cs) =>{
  return axios.post(baseUrlUol+'Api/SpeakOut/SpeakOut_Common/WorksUpload?videourl='+url+'&studentid='+id+'&title='+til+'&describe='+cs+'&type=0&'+Token);
};

export const saveInformationImagePC = (file) =>{
  return axios.post(baseUrlUol+'/Api/SpeakOut/SpeakOut_Common/WorksUploadPC?'+Token,file, {
    headers: {'Content-Type': 'multipart/form-data'}
  });
};

export const reloadsd= (file,id) => {
  let client = new OSS({
    region: "oss-cn-hangzhou", 
    accessKeyId: "LTAI4GBKJYSp9dG1otK5QFYS",
    accessKeySecret: "1KcF2TdLTD9m35G2VfqUekOrdQ5unQ",
    bucket: "abie" 
  });
  var suffix = file[0].name.substr(file[0].name.indexOf(".")).toLowerCase(); // 获取文件后缀名

  var filename = new Date().getTime() + suffix; // 为文件命名
  console.log('filename',filename)

  return client.multipartUpload("speakout/"+onlyDate(new Date().getTime())+"/" + filename, file[0])
}
export const saveInforScan = (d) =>{
  return axios.get(baseUrl+'Api/SpeakOut/SpeakOut_Common/CreateQRCode?worksid='+d+"&url="+encodeURIComponent(baseUrld+"?id="+d)+"&"+Token)
};

export const wxFengx=(i)=>{
  // const urlsd= encodeURIComponent(window.location.href)
  const urlsd=window.location.href
  // const urls='http://it-test.abiechina.com:9005/'+c
  //  const urlsd='http://speakout.abiechina.com/'+c
  // const urlsdd=urlsd.split('#/')[0]+urlsd.split('#/')[1]
  // const urlsds=urlsdd.split('?')[0]
  const urls= encodeURIComponent(urlsd)
  // console.log('res',window.location.href.split('#/')[0]+window.location.href.split('#/')[1])
  console.log('baseUrl+`api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token',baseUrl+`api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token)
  // return axios.post(`https://sp.abiechina.com/api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token).then((res)=>{
    return axios.post(baseUrl+`api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token).then((res)=>{
    const {appId,timestamp,nonceStr,signature,url}=res.data.ResultInfo
    // Toast.info(urls,url,appId,timestamp,nonceStr, signature,5)
    wx.config({
      debug: false, 
      appId, 
      timestamp,
      nonceStr, 
      signature,
      jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage']
    });
    wx.ready(function () {
      wx.onMenuShareAppMessage({ 
        title: '爱贝英语Speak Out',
        desc: '第七届云Speak Out火热进行中！秀出每一个优秀的自己！',  
        // link: 'https://speakout.abiechina.com',   //生产环境
        link: baseUrls,                              //测试环境
        imgUrl: 'https://sp.abiechina.com:553/Upload/PastPeriod/shoIcon.jpg',  //生产环境or测试环境
        success: function () {  
          
        }
      })
      wx.onMenuShareTimeline({ 
        title: '第七届云Speak Out火热进行中！秀出每一个优秀的自己！',
        // link: 'https://speakout.abiechina.com',   //生产环境
        link: baseUrls,                              //测试环境
        imgUrl: 'https://sp.abiechina.com:553/Upload/PastPeriod/shoIcon.jpg',  //生产环境or测试环境
        success: function () {
       
        }
      })
      wx.error(function(res){
        i!==3&&axios.post(baseUrl+`api/SpeakOut/SpeakOut_Common/WX_ReloadJDKModel?url=${urls}&`+Token).then((res)=>{
          showerAser(3)
        })
    });
    });

  })
}
export const showerAser=(id,pio,i)=>{
  localStorage.removeItem("useUrl");
  // const urlsd= encodeURIComponent(window.location.href)
  const urlsd=window.location.href
  // const urls='http://it-test.abiechina.com:9005/'+c
  //  const urlsd='http://speakout.abiechina.com/'+c
  // const urlsdd=urlsd.split('#/')[0]+urlsd.split('#/')[1]
  // const urlsds=urlsdd.split('?')[0]
  const urls= encodeURIComponent(urlsd)
  // console.log('res',window.location.href.split('#/')[0]+window.location.href.split('#/')[1])
  // return axios.post(`https://sp.abiechina.com/api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token).then((res)=>{
    return axios.post(baseUrl+`api/SpeakOut/SpeakOut_Common/WX_JDKModel?url=${urls}&`+Token).then((res)=>{
    const {appId,timestamp,nonceStr,signature,url}=res.data.ResultInfo
    // // Toast.info(urls,url,appId,timestamp,nonceStr, signature,5)
    wx.config({ 
      debug: false, 
      appId, 
      timestamp,
      nonceStr, 
      signature,
      jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage']
    });
    wx.ready(function () {
      wx.onMenuShareAppMessage({ 
        title: '喜欢我，请为我点赞！',
        desc: '快来看看我为第七届爱贝英语风采盛典准备的视频吧！',  
        link: baseUrld+'?id='+id,   //生产环境
        // link: baseUrld+'?id='+id,                              //测试环境
        imgUrl: 'https://sp.abiechina.com:553/Upload/PastPeriod/shoIcon1.jpg',  //生产环境or测试环境
        success: function () { 
          // window.location.href = 'http://it-test.abiechina.com:9005'   //测试环境
          // window.location.href = 'https://speakout.abiechina.com'   //正式环境

        }
      })
      wx.onMenuShareTimeline({ 
        title: '快来看看我为第七届爱贝英语风采盛典准备的视频吧！',
        link:  baseUrld+'?id='+id,   //生产环境
        // link: baseUrld+'?id='+id,                              //测试环境
        imgUrl: 'https://sp.abiechina.com:553/Upload/PastPeriod/shoIcon1.jpg',  //生产环境or测试环境

        success: function () {
          // window.location.href = 'http://it-test.abiechina.com:9005' //测试环境
          // window.location.href = 'https://speakout.abiechina.com'   //正式环境
        }
      })
        wx.error(function(res){
          i!==3&&axios.post(baseUrl+`api/SpeakOut/SpeakOut_Common/WX_ReloadJDKModel?url=${urls}&`+Token).then((res)=>{
            showerAser(id,pio,3)
          })
      });
    });

  })
}
