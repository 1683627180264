import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';
import { Upload, } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

import "../../../../node_modules/video-react/dist/video-react.css"
import AbieButton from './kits/button'
import{banners,saveInformationImagePC,getStated,saveInforScan,reloadsd,saveInformationImage} from '../actions/index';
import { Player, ControlBar,BigPlayButton } from 'video-react';

class PcUp extends Component {
  constructor(props) {
    super(props);
    const methods = ['copyUrl2','cansol','textarea','textarea1','previewVideo','submitnot','goLuder','beforeUpload','subNot'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      currenCont:0,
      currenCont:0,
      currenC1:'',
      currenC:'',
      stdId:0,
      filesData:{},
      useData:{},
      currenCont1:"0",
      text3:'sdaw',
      show:false,
      show1:false,
      NetUrl:'二维码生成中...'
    };
  }
  subNot(){
    Toast.fail('上传通道已关闭', 5);
  }
  goLuder(){
    this.props.history.push({pathname: `/load`})
  }

  textarea(e){
    if(e.target.value.length<=30){
      this.setState({
        currenCont:e.target.value.length,
        currenC:e.target.value
      })
    } 
  }

  textarea1(e){
    if(e.target.value.length<=50){
      this.setState({
        currenCont1:e.target.value.length,
        currenC1:e.target.value
      })
    }
  }


  previewVideo = (file) => {
    // 建议判断下视频大小及格式，太大的可能会有问题
    const reader = new FileReader();
    reader.onload = (evt) => {
      this.videoCode.src = evt.target.result;
    };
    reader.readAsDataURL(file);
}

  componentDidMount() {
    if (navigator.onLine){}else { Toast.fail('当前网络不佳，请稍后再试', 3);}
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    this.setState({
      stdId:this.props.match.params.id
    })
    const {StudentID}=useData
    const that=this
    this.props.getStated(StudentID,that)
  };

  state = {
    loading: false,
  };

  beforeUpload(file,fileList){
    this.setState({
      show:true
    });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let thist=this
    reader.onload = function(e) {
      thist.setState({
        baseNuber:e.target.result,
      });
    };
    const fileSiz=file.size/1024/1024
    if(fileSiz>200){
      Toast.fail('请上传低于200M文件', 3);
      this.setState({
        show:false
      });
    }else{
      reloadsd(fileList,this.state.stdId).then(function (result){
          var url = result.res.requestUrls[0];
          var length = url.lastIndexOf('?');
          var imgUrl = length>0?url.substr(0,length):url; // 文件最终路径
          let imgUrlTo=imgUrl.toLowerCase().replace('http://','https://')
          thist.setState({
            showPlauer:true,
            show:false,
            imgUrlTo,
          });
          thist.props.getStated(thist.state.useData.StudentID,thist)
          thist.setState({
            show:false,
            flss:true
          })
        }).catch(function (err) {
          thist.setState({
            show:false,
          })
      });
      this.setState({ 
        filesData:fileList[0],
      })
    }
  }

  copyUrl2(){
    let j= copy(this.ahrfCode.innerHTML)
    Toast.success('已复制好，可贴粘', 3);
  }

  submitnot(){
    const {filesData,currenC1,currenC,stdId}=this.state
    let thast=this
    if(!this.state.imgUrlTo){
      Toast.fail("请上传视频！", 3);
    }else{
      if(!currenC){
        Toast.fail("请填写视频中所表演的节目名字！", 3);
      }else{
        currenC&&saveInformationImage(this.state.imgUrlTo,this.state.stdId,currenC,currenC1).then((res) => {
          const worksid= res.data.ResultInfo.Data
          if(res.data.ResultInfo.ErrCode===0){
              saveInforScan(worksid.WorksID).then((tts)=>{
                thast.setState({
                show:false,
                show1:true,
                NetUrl:tts.data.ResultInfo.Data.NetUrl
              })
            })
          }else if(res.data.ResultInfo.ErrCode===1){
            thast.setState({
              show:false,
            })
            Toast.fail(res.data.ResultInfo.Message, 3);
          }else{
            thast.setState({
              show:false,
            })
            Toast.fail(res.data.ResultInfo.Message, 3);
          }
        })
      }
    }

    // console.log('filesData',filesData)
    // var reader = new FileReader();
    // filesData.originFileObj&&reader.readAsDataURL(filesData.originFileObj);
    // let thast=this
    // var fileObj = filesData.originFileObj;       
    // var formData = new FormData();
    // formData.append('file1', fileObj);
    // formData.append('type', 0);
    // formData.append('describe', currenC1);
    // formData.append('title', currenC);
    // formData.append('studentid', stdId);
    // console.log(formData,fileObj)
    //   if((!currenC)){
    //     JSON.stringify(filesData)==="{}"?Toast.fail('请上传视频', 3):Toast.fail('请填写视频中所表演的节目名字', 3)
    //   }else{
    //   this.setState({
    //     show:true
    //   })
    //    saveInformationImagePC(formData).then((res) => {
    //       const worksid= res.data.ResultInfo.Data
    //       if(res.data.ResultInfo.ErrCode===0){
    //         saveInforScan(worksid.WorksID).then((tts)=>{
    //           this.setState({
    //             show:false,
    //             show1:true,
    //             NetUrl:tts.data.ResultInfo.Data.NetUrl
    //           })
    //         })
    //         // thast.props.history.push({pathname: `/mydetailPc/${worksid.WorksID}`})
            
    //       }else if(res.data.ResultInfo.ErrCode===1){
    //         this.setState({
    //           show:false
    //         })
    //         Toast.fail(res.data.ResultInfo.Message, 3);
    //       }else{
    //         this.setState({
    //           show:false
    //         })
    //         Toast.fail(res.data.ResultInfo.Message, 3);
    //       }
    //     })
    //   }
  }
  cansol(){
    // this.props.history.push({pathname: `/mydetailPc/${worksid.WorksID}`})
  }
  render() {
    const uploadButton = (
      <div>
      </div>
    );
    const { imageUrl,show,show1 } = this.state;
    const {StudentName,OrgName} = this.state.useData
    // const {StudentName,OrgName}=this.props.types.Student
    return (
      <div className="loadbac1 load-scoll1">
        {show&&<div>
          <div className="ab-modal-mask"></div>
          <div className="ab-modal-warp ab-warp spd">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt="" />
                <div>
                  上传中...<br></br>请勿关闭此页面
                </div>
            </div>
					</div>
        </div>}
        {show1&&<div>
          <div className="ab-modal-mask"></div>
          <div className="ab-modal-warp ab-warp wrps">
            <div style={{width:"18%",padding: "18px 38px"}}>
              {/* <img onClick={this.cansol} className="cancelDef" src={require('../../../assets/pc/cancel.png')} alt=""/> */}
              <div style={{fontSize:'18px',lineHeight: "24px"}}>上传成功！</div>
              <img src={this.state.NetUrl} alt="" srcSet=""/>
              <div style={{fontSize:'12px',lineHeight: "16px"}}>使用微信扫描二维码，在手机端查看作品</div>
            </div>
					</div>
        </div>}
        <img className="load-logo1" src={require('../../../assets/pc/upTop-26.png')} alt=""></img>
        <div className="sef-head1">
            <div className="pc-topTips">
                {/* <h1>恭喜宝宝进入{(this.props.types.Status===4||this.props.types.Status===5)?"复赛":"决赛"}角逐!</h1> */}
                <h1>恭喜宝宝进入决赛预选!</h1>
                <h5>请先确认您的个人信息</h5>
                <div>
                  <div>姓名:<a>{StudentName}</a></div>
                  <div> 中心:<a>{OrgName}</a></div>
                  <div>学习账号:<a>{this.props.types.Student?this.props.types.Student.StudentCode:''}</a></div>
                </div>
            </div>
        </div>
        <div className="form-content1">
          <div className="tab-lit-cont1 apply player1">
            <div className="up-bac1">
                <div className="up-sp1">
                  {this.props.types.OpenRematch?(<Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action=""
                    accept="video/mp4"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>):<div style={{width:'100%',height:"20rem"}} onClick={this.subNot}></div>}
                    {this.state.showPlauer&&<Player
                      playsInline={true}
                      ref={player => {
                        this.player = player;
                      }}
                      autoPlay
                    >
                      <BigPlayButton position="center" />
                        <source src={this.state.imgUrlTo} />
                      <ControlBar autoHide={false} />
                    </Player>}
                </div>     
            </div>
            <div className="up-insert1">
              <div>*</div>
              <textarea value={this.state.currenC} name="" cols="30" rows="2" onChange={this.textarea} placeholder="视频中所表演的节目名字"></textarea>
              <p><span>{this.state.currenCont}</span>/<span>30</span></p>
            </div>
            <div className="up-insert1 up-insert2">
              <div> </div>
              <textarea name="" value={this.state.currenC1} cols="30" rows="4" onChange={this.textarea1} placeholder="写下你的参赛宣言,让你的优秀被更多人发现!"></textarea>
              <p><span>{this.state.currenCont1}</span>/<span>50</span></p>
            </div>
            <div className='button-grop'> 
              <button className="btn-seach21">{this.props.types.Student?this.props.types.Student.GroupName:'暂无编组'}</button>
              {this.props.types.OpenRematch?<AbieButton className="btn-seach22" onClick={this.submitnot}>提交作品</AbieButton>:
              <AbieButton className="btn-seach22 noPass" onClick={this.submitnot}>上传通道已关闭</AbieButton>}
            </div>
            <div className="botsd">
                如发现您的个人信息和实际不符，请致电：400 088 1298
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,types}} = state;
  return {bannerResource,types}
};

const mapDispatchToProps =  {
  banners,
  getStated
};

export default connect(mapStateToProps, mapDispatchToProps)(PcUp);