import React, {Component} from 'react';
import {Provider} from 'react-redux';
import { HashRouter,Route } from "react-router-dom";

import Showpage from './components/showPage.jsx';
import Myselfs from './components/myself.jsx';
import Isloads from './components/load.jsx';
import Detail from './components/detail.jsx';
import Uploadd from './components/uplode.jsx';
import Aplay from './components/aplay.jsx';
import FileViewerd from './components/userAppaly.jsx';
import PcTips from './components/talls.jsx';
import Iswx from './components/iswx.jsx';
import Singer from './components/mine.jsx';
import Mydetal from './components/mydetail.jsx';
import PcIsloads from './components/loadPc.jsx';
import PcUp from './components/uploadPc.jsx';
import MydetalPc from './components/mydetailPc.jsx';
import NewsData from './components/newsData.jsx';
import Lcs from './components/userPromis.jsx';
import ToRouter from './components/toRouter.jsx';
import store from './store';
import './style.scss';

export default class Shower extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Route path="/" exact component={Showpage} />
          <Route path="/myself/:id" exact component={Myselfs} />
          <Route path="/load" exact component={Isloads} />
          <Route path="/detail/:id" exact component={Detail} />
          <Route path="/uplode/:id" exact component={Uploadd} />
          <Route path="/aplay/:id" exact component={Aplay} />
          <Route path="/mine/:id" exact component={Singer} />
          <Route path="/mydetail/:id" exact component={Mydetal} />
          <Route path="/loadPc" exact component={PcIsloads} />
          <Route path="/uploadPc/:id" exact component={PcUp} />
          <Route path="/mydetailPc/:id" exact component={MydetalPc} />
          <Route path="/newsData/:id" exact component={NewsData} />
          <Route path="/toRouter/:id" exact component={ToRouter} />
          <Route path="/talls" exact component={PcTips} />
          <Route path="/iswx" exact component={Iswx} />
          <Route path="/userPromis" exact component={Lcs} />
          <Route path="/userAppaly" exact component={FileViewerd} />
        </HashRouter>
      </Provider>
    );
  };
}
