import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';
import { Upload, message } from 'antd';
import copy from 'copy-to-clipboard';
import { Player, ControlBar,BigPlayButton } from 'video-react';

import "../../../../node_modules/video-react/dist/video-react.css"
import {ismobile,isWeixing} from "../../../shared/utils/common"

import {AbieButton,AbieVideo} from './kits'
import{banners,saveInformationImage,getStated,edictTex,reloadsd} from '../actions/index'

class Uploadd extends Component {
  constructor(props) {
    super(props);
    const methods = ['hideEr','copyUrl2','textarea','textarea1',
    'previewVideo','submitnot','goLuder','previewFile','beforeUpload'
    ,'onBlurs','goLuder1','shows','subNot'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      currenCont:0,
      currenC1:'',
      currenC:'',
      stdId:0,
      filesData:{},
      useData:{},
      baseNuber:'',
      showPlauer:false,
      currenCont1:0,
      show:false,
      worksid:'',
      flss:false,
      showed:false,
      imgUrlTo:'',
      imgUrlUp:true
    };
  }

  previewFile(res){
    console.log(res)
  }

  subNot(){
    Toast.info('上传通道已关闭', 5);
  }

  hideEr(){
    this.setState({
      showed:false
    })
  }

  goLuder(){
    this.props.history.push({pathname: `/`})
    localStorage.removeItem("useData");
  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  textarea(e){
    console.log(e.target.value)
    if(e.target.value.length<=30){
      this.setState({
        currenCont:e.target.value.length,
        currenC:e.target.value
      })
    } 
  }

  textarea1(e){
    if(e.target.value.length<=50){
      this.setState({
        currenCont1:e.target.value.length,
        currenC1:e.target.value
      })
    }
  }


  previewVideo = (file) => {
    console.log(file)
    // 建议判断下视频大小及格式，太大的可能会有问题
    const reader = new FileReader();
    console.log(reader.onload)
    reader.onload = (evt) => {
      console.log(evt.target.result)
      this.videoCode.src = evt.target.result;
    };
    reader.readAsDataURL(file);
}
  
  componentDidMount() {
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    if (navigator.onLine){}else { Toast.info('当前网络不佳，请稍后再试', 3);}
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    this.setState({
      stdId:this.props.match.params.id
    })
    const {StudentID}=useData
    const that=this
    this.props.getStated(StudentID,'1')
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  state = {
    loading: false,
  };

  beforeUpload(file,fileList){
    this.setState({
      show:true
    });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let thist=this
    reader.onload = function(e) {
      thist.setState({
        baseNuber:e.target.result,
      });
    };
    const fileSiz=file.size/1024/1024
    if(fileSiz>200){
      Toast.info('请上传低于200M文件', 3);
    }else{
      reloadsd(fileList,this.state.stdId).then(function (result){
        console.log('result:',result)
          var url = result.res.requestUrls[0];
          var length = url.lastIndexOf('?');
          var imgUrl = length>0?url.substr(0,length):url; // 文件最终路径
          let imgUrlTo=imgUrl.toLowerCase().replace('http://','https://')
          thist.setState({
            showPlauer:true,
            show:false,
            imgUrlTo,
          });
          // Toast.info('上传成功', 3);
          thist.props.getStated(thist.state.useData.StudentID,thist)
          thist.setState({
            show:false,
            flss:true
          })
        }).catch(function (err) {
   
      });
      // var formData = new FormData();
      // formData.append('file1', fileList[0]);
      // console.log('file',file)
      // let thast=this
      // saveInformationImage(formData,this.state.stdId).then((res) => {
      //   console.log('res',res.data.ResultInfo.Data)
      //   thast.setState({
      //     worksid:res.data.ResultInfo.Data
      //   })
      //   if(res.data.ResultInfo.ErrCode===0){
      //     thast.setState({
      //       showPlauer:true,
      //       show:false
      //     });
      //     // Toast.info('上传成功', 3);
      //     this.props.getStated(this.state.useData.StudentID,thast)
      //     this.setState({
      //       show:false
      //     })
      //   }else if(res.data.ResultInfo.ErrCode===1){
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false,
      //       showed:true
      //     });
      //   }else{
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false,
      //       showed:true
      //     });
      //   }
      // }).catch((res)=>{
      //   thast.setState({
      //     showPlauer:true,
      //     show:false,
      //     showed:true
      //   });
      // })

      this.setState({ 
        filesData:fileList[0],
      })
    }
  }

  handleChange = ({file,fileList}) => {
    // console.log('222',fileList[0].originFileObj,file)
    
  };

  goLuder1(){
    this.props.history.push({pathname: `/`})
  }

  async shows(){
    await this.setState({
      flss:true,
      imgUrlUp:false
    })
    this.player.play()
  }

  copyUrl2(){
    let j= copy(this.ahrfCode.innerHTML)
    Toast.info('已复制好，可贴粘', 3);
  }

  // componentWillUnmount(){
  //   if((!this.props.types.Works)||(!this.state.currenC)){
  //     Toast.info("请上传视频！", 3);
  //   }
  // }

  submitnot(){
    const {currenC,currenC1}=this.state
    let thast=this
    if(!this.state.imgUrlTo){
      Toast.info("请上传视频！", 3);
    }else{
      if(!currenC){
        Toast.info("请填写视频中所表演的节目名字！", 3);
      }else{
        currenC&&saveInformationImage(this.state.imgUrlTo,this.state.stdId,currenC,currenC1).then((res) => {
          const worksid= res.data.ResultInfo.Data
          if(res.data.ResultInfo.ErrCode===0){
            Toast.info('上传成功', 3);
            this.setState({
              show:false
            })
            thast.props.history.push({pathname: `/mydetail/${res.data.ResultInfo.Data.WorksID}`})
          }else if(res.data.ResultInfo.ErrCode===1){
            Toast.info(res.data.ResultInfo.Message, 3);
          }else{
            Toast.info(res.data.ResultInfo.Message, 3);
          }
        })
      }
    }
  }

  render() {
    const uploadButton = (
      <div>
      </div>
    );
    const { imageUrl,show,showed} = this.state;
    console.log('111',this.props.types.Works)
    // const {CoverUrl,NetUrl}=this.props.types.data.ResultInfo.Data.Works
    return (
      <div className="loadbac load-scoll">
        {show&&<div>
          <div className="ab-modal-mask"></div>
          <div className="ab-modal-warp ab-warp">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt=""/>
                <div>
                  上传中...<br></br>请勿关闭此页面
                </div>
            </div>
					</div>
        </div>}
        {this.state.showed&&<div>
          <div className="ab-modal-mask" onClick={this.hideEr}></div>
          <div className="ab-modal-warp">
            <div>
              <div className="shoDetal">
                <img style={{width:"26rem"}} src={require('../../../assets/images/scan.png')} alt=""></img>
                <AbieButton className="load-sd strd" onClick={this.hideEr}>知道了</AbieButton>
              </div>
            </div>
					</div>
        </div>}
        <div className="sef-head">
          <img src="" src={require('../../../assets/images/def_tx1.png')} alt=""/>
          <div>
            <p>{this.props.types.Student?this.props.types.Student.StudentName:'暂无数据'}</p>
            <p>{this.props.types.Student?this.props.types.Student.OrgName:'暂无数据'}</p>
          </div>
          <div>
            <button className={"btn-out"} onClick={this.goLuder}>登出账号</button>
          </div>
        </div>
        <div className="form-content">
          <div className="tab-lit-cont apply player plaer">
            <div className="up-top-tips">
                <img src={require('../../../assets/images/ds-25.png')} alt=""/>
                <div className="up-time-tips">
                    {/* <p>宝宝获得{(this.props.types.Status===4||this.props.types.Status===5)?"复赛":"决赛"}资格啦,请上传参赛作品</p> */}
                    <p>宝宝获得决赛预选资格啦,请上传评选视频</p>
                    <p><img src={require('../../../assets/images/up_tips-26.png')} alt=""/>(截止时间2020.5.24 23点前)</p>
                </div>
            </div>
            <div className="up-bac">
                <div className="up-sp esd posUrl">
                {this.props.types.OpenRematch?(!this.state.imgUrlTo&&<Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    accept="video/*"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    capture=""
                  >
                    {this.state.imgUrlTo ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>):<div style={{width:'100%',height:"20rem"}} onClick={this.subNot}></div>}
                    {/* {this.state.showPlauer&&<AbieVideo
                      videoUrl={this.state.baseNuber}
                      videoHeight="100%"
                      className='videBox'CoverUrl,NetUrl
                    >
                      </AbieVideo>} */}
                    {this.state.imgUrlTo&&this.state.imgUrlUp&&<div className="plauAbie abiepl" style={{width:'100%',zIndex:"10"}} onClick={this.shows}>
      
                    </div>}
                    {this.state.imgUrlTo&&<Player
                      playsInline={true}
                      fluid={true}
                      ref={player => {
                        this.player = player;
                      }}
                      src={this.state.imgUrlTo} 
                    >
                      <BigPlayButton position="center" />
                        <source src={this.state.imgUrlTo}  />
                      <ControlBar/>
                    </Player>
                    }
                </div>     
            </div>
            <div className="up-insert">
              <div>*</div>
              <textarea onBlur={this.onBlurs} name=""  value={this.state.currenC} cols="30" rows="2" onChange={this.textarea} placeholder="视频中所表演的节目名字"></textarea>
              <p><span>{this.state.currenCont}</span>/<span>30</span></p>
            </div>
            <div className="up-insert">
              <div> </div>
              <textarea onBlur={this.onBlurs} value={this.state.currenC1} name="" cols="30" rows="4" onChange={this.textarea1} placeholder="写下你的参赛宣言,让你的优秀被更多人发现!"></textarea>
              <p><span>{this.state.currenCont1}</span>/<span>50</span></p>
            </div>
            <button className="btn-seach2">{this.props.types.Student?this.props.types.Student.GroupName:'暂无数据'}</button>
            {this.props.types.OpenRematch?<AbieButton className="load-sd btmss" onClick={this.submitnot}>提交作品</AbieButton>:
              <AbieButton className="load-sd btmss noPass">上传通道已关闭</AbieButton>
            }
            <div className="ap-kf ap-kfd">
              <p>如上传遇到问题，可用电脑前往</p>
              <p onClick={this.copyUrl2}><a ref={(ahrf)=>{this.ahrfCode=ahrf}}>https://speakout.abiechina.com/#/loadPc</a><a style={{borderBottom: "1px solid"}}> 点击此处复制链接</a></p>
              <p>活动客服电话: 400 088 1298</p>
            </div>
          </div>
        </div>
        <button className="btn-out1" onClick={this.goLuder1}>返回首页</button>
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,types}} = state;
  return {bannerResource,types}
};

const mapDispatchToProps =  {
  banners,
  getStated
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploadd);