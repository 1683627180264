import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import { Toast,DatePicker,List} from 'antd-mobile';

import {ispone} from '../../../shared/regExp'
import {formatDateTime,formatDateToUtc} from '../../../shared/utils/common'
import AbieButton from './kits/button'
import {ismobile,isWeixing} from "../../../shared/utils/common"
import{banners,aplayIn} from '../actions/index'

class Singer extends Component {
  constructor(props) {
    super(props);
    const methods = ['timeClick','aplay','canIn','modlHide','goLuder'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      suc:true,
      show:false,
      date:'',
      stdId:'',
      useData:{}
    };
  }

  goLuder(){
    this.props.history.push({pathname: `/`})
    localStorage.removeItem("useData");
  }

  aplay(){
    this.setState({
      show:true,
    })
  }

  modlHide(){
    this.setState({
      show:false,
    })
  }

  canIn(){
    const {stdId,date}=this.state
    aplayIn(stdId,formatDateTime(date)).then((res)=>{
      console.log(res)
      this.setState({
      suc:false,
      })
    })
  }

  timeClick(){
     if(this.state.times==='获取验证码'){
       if(ispone(this.state.inputValue)){
        var sta=60
        let pop=this
        let ppts=setInterval(function(){
          sta-=1
          pop.setState({
          times:sta+"s"
          })
          if(sta===0){
            pop.setState({
              times:'获取验证码',
              stime:true
            })
            clearInterval(ppts)
          }
        },1000);
        this.setState({
          stime:false,
          times:60+"s",
        })
       }else{
        Toast.info('请输入正确的手机号码', 3);
       }
    }
  }

  
  componentDidMount() {
    // // const {banners} = this.props;
    // banners();
    if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    var useData = JSON.parse(localStorage.getItem("useData"));
    console.log(useData)
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    this.setState({
      stdId:this.props.match.params.id
    })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  render() {
    const {show} =this.state
    const {Mobile} = this.state.useData
    return (
      <div className="loadbac details">
        <div className="sef-head">
          <img src="" src={require('../../../assets/images/def_tx.png')} alt=""/>
          <div>
            <p>{Mobile}</p>
            <p>还未绑定学习账号</p>
          </div>
          <div>
            <button className={"btn-out"} onClick={this.goLuder}>登出账号</button>
          </div>
        </div>
        <div className="form-content">
          <div className="tab-lit-cont apply">
            <div>
                <div className="ybs">
                  <div className="tab-tips">感谢您对Speak Out活动的关注!<br/>我们会尽快安排课程老师跟您联系!</div>
                  <img style={{marginTop: "4rem"}} src={require('../../../assets/images/gzs.png')} alt="" srcSet=""/>
                </div>
              <p style={{marginTop:"-8rem",color:"#1776C3"}}>
                长按二维码,关注"爱贝英语公众号"了解更多咨询。
              </p>
              <div className="ap-kf">
                <p>活动客服电话: 400 088 1298</p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-bot">
          <img src={require('../../../assets/images/bot_log.png')} alt=""/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource}} = state;
  return {bannerResource}
};

const mapDispatchToProps =  {
  banners
};

export default connect(mapStateToProps, mapDispatchToProps)(Singer);