import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import {onlyDate} from '../../../shared/utils/common'

import{getNewsData} from '../actions/index'
import {ismobile,isWeixing} from "../../../shared/utils/common"
import {formatDateToUtc} from '../../../shared/utils/common'

class Lcs extends Component {

  constructor(props) {
    super(props);
    const methods = ['loadPage'];
    methods.forEach((item) => this[item] = this[item].bind(this));

  }
state = {
    useData:{},
    Child:true
  }
  componentDidMount() {
    // if(!isWeixing())this.props.history.push({pathname: `/iswx`,});
    let crentTime = formatDateToUtc(new Date())
    let varryTime="2020-06-01T00:00:00"
    crentTime>formatDateToUtc(varryTime)&&this.setState({
      Child:false
    })
    document.title = `活动日程`;
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      })
    }
    this.setState({
      stdId:this.props.match.params.id
    })
    this.props.getNewsData(this.props.match.params.id)
    if(!ismobile())this.props.history.push({pathname: `/talls`,});
  };
  loadPage(){
    this.props.history.push({pathname: `/load`,})
  }
  render() {
    return (
      <div className="lcs">
        <div>
          {this.state.Child?(JSON.stringify(this.state.useData) ==="{}"?
          <div>
            <img src={require('../../../assets/images/lc-02.png')} alt="" srcset=""/>
            <div onClick={this.loadPage} className="but-toi"></div>
          </div>:
          <img src={require('../../../assets/images/lc-023.png')} alt="" srcset=""/>):
          <img src={require('../../../assets/images/actionGronp.jpg')} alt="" srcset=""/>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {nesDatas}} = state;
  return {nesDatas}
};

const mapDispatchToProps =  {
  getNewsData
};

export default connect(mapStateToProps, mapDispatchToProps)(Lcs);