import React, {Component} from 'react';
import { Toast} from 'antd-mobile';
import PropTypes from 'prop-types';

import './index.scss';

class AbModal extends Component {

	static propTypes = {
    type: PropTypes.string,
		timeClick:PropTypes.func,
		bindPone:PropTypes.func,
  };
	
	constructor(props) {
    super(props);
    const methods = ['poneChange','ridosed','yzChange','onBlurs'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      stime:true,
      inputValue:'',
      ridosed:false,
      yzValue:'',
      yzBlo:false,
      pass:'',
    };
  }

  poneChange(e){
    e.target.value.length<12&&this.setState({
      inputValue:e.target.value
    })
  }
  yzChange(e){
    e.target.value.length<5&&this.setState({
      yzValue:e.target.value
    })

  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  ridosed(){
    this.setState({
      ridosed:true
    })
  }
	componentDidMount() {

	};
	render() {
		const {timeClick,bindPone}=this.props
		return (
				<div>
						<div className="ab-modal-mask"></div>
						<div className="ab-modal-warp">
								<div className="ad-modal-cont">
										<div className="ad-modal-bac">
												<p>确定是您孩子的账号？</p>
												<div>
														<input onBlur={this.onBlurs} value={this.state.inputValue} onChange={this.poneChange} ref={(input)=>{this.mobile=input}} placeholder="手机号" type="number" />
														<button className={this.state.stime?"":"btn-disab"} onClick={timeClick}>{this.state.times}</button>
												</div>
												<div>
														<input onBlur={this.onBlurs} value={this.state.yzValue} ref={(input)=>{this.verificationCode=input}} onChange={this.yzChange} type="number"  placeholder="请输入验证码"/>
												</div>
												<button onClick={bindPone}>登录</button>
										</div>
								</div>
						</div>
				</div>
		);
	}
}


export default AbModal;