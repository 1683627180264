import { combineReducers } from 'redux';
import {
    BANNERS,
    GET_NUM,
    GET_PEO,
    IS_LOAD,
    GET_NEWS,
    GET_DETAILE,
    GET_PRO,
    GET_CILDLIST,
    GET_NEWSDATA,
    GET_TYPES
  } from '../constants';

  const speakout = (state = {
    bannerResource: [],
    getnum:'',
    allPeo:[],
    allTilt:[],
    islogin:false,
    news:[],
    detail:{},
    getProdata:[],
    chidList:[],
    nesDatas:[],
    types:{}
  }, action) => {
    switch (action.type) {
      case GET_TYPES:
        return {
          ...state,
          types: action.payload
        };
      case BANNERS:
        return {
          ...state,
          bannerResource: action.payload
        };
      case GET_NUM:
        return {
          ...state,

          getnum: action.payload
        };
      case GET_PEO:
        return {
          ...state,
          allPeo: action.payload,
          allTilt: action.payloads
        };
      case IS_LOAD:
        return {
          ...state,
          islogin: action.payload,
        };
      case GET_NEWS:
        return {
          ...state,
          news: action.payload,
        };
      case GET_DETAILE:
        return {
          ...state,
          detail: action.payload,
        };
      case GET_PRO:
        return {
          ...state,
          getProdata: action.payload,
        }
      case GET_CILDLIST:
        return {
          ...state,
          chidList: action.payload,
        }
      case GET_NEWSDATA:
        return {
          ...state,
          nesDatas: action.payload,
        }
      default:
        return state;
    }
  };
  
  const rootReducer = combineReducers({
    speakout
  });
  
  export default rootReducer;