import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';
import { Upload, message } from 'antd';
import copy from 'copy-to-clipboard';

import "../../../../node_modules/video-react/dist/video-react.css"
import AbieButton from './kits/button'
import{banners,saveInformationImage,getStated,reloads,edictTex,saveInforScan} from '../actions/index';
import { Player, ControlBar,BigPlayButton } from 'video-react';

class MydetalPc extends Component {
  constructor(props) {
    super(props);
    const methods = ['copyUrl2','textarea','textarea1','previewVideo','submitnot','goLuder','beforeUpload','edict'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      currenCont:0,
      currenCont:0,
      currenC1:'',
      currenC:'',
      stdId:0,
      filesData:{},
      useData:{},
      currenCont1:"0",
      text3:'sdaw',
      show:false,
      canEl:true
    };
  }

  edict(){
    this.setState({
      canEl:false,
      currenC:this.props.types.Works.WorksName,
      currenC1:this.props.types.Works.WorksDescribe,
    })
  }

  goLuder(){
    this.props.history.push({pathname: `/load`})
  }

  textarea(e){
    if(e.target.value.length<=30){
      this.setState({
        currenCont:e.target.value.length,
        currenC:e.target.value
      })
    } 
  }

  textarea1(e){
    if(e.target.value.length<=50){
      this.setState({
        currenCont1:e.target.value.length,
        currenC1:e.target.value
      })
    }
  }


  previewVideo = (file) => {
    // 建议判断下视频大小及格式，太大的可能会有问题
    const reader = new FileReader();
    reader.onload = (evt) => {
      this.videoCode.src = evt.target.result;
    };
    reader.readAsDataURL(file);
}

  componentDidMount() {
    if (navigator.onLine){}else { Toast.info('当前网络不佳，请稍后再试', 3);}
    var useData = JSON.parse(localStorage.getItem("useData"));
    if(useData){
      this.setState({
        useData
      })
    }else{
      this.props.history.push({pathname: `/load`,})
    }
    document.title = `个人中心`;
    this.setState({
      stdId:this.props.match.params.id
    })
    const {StudentID}=useData
    const that=this
    this.props.getStated(StudentID,that)
  };

  state = {
    loading: false,
  };

  beforeUpload(file,fileList){
    this.setState({
      show:true
    });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let thist=this
    reader.onload = function(e) {
      thist.setState({
        baseNuber:e.target.result,
      });
    };
    const fileSiz=file.size/1024/1024
    console.log('fileSiz',fileSiz)
    if(fileSiz>200){
      Toast.info('请上传低于200M文件', 3);
      this.setState({
        show:false
      });
    }else{
      reloads(fileList,this.props.types.Works.WorksID).then((res)=>{
        thist.props.getStated(thist.state.useData.StudentID,thist)
        thist.setState({
          show:false
        })
      })

      // var formData = new FormData();
      // formData.append('file1', fileList[0]);
      // let thast=this
      // reloads(formData,this.state.stdId).then((res) => {
      //   console.log('res',res.data.ResultInfo.Data)
      //   thast.setState({
      //     worksid:res.data.ResultInfo.Data
      //   })
      //   if(res.data.ResultInfo.ErrCode===0){
      //     thast.setState({
      //       showPlauer:true,
      //       showed:false
      //     });
      //     Toast.info('上传成功', 3);
      //     this.props.getStated(this.state.useData.StudentID,thast)
      //     this.setState({
      //       show:false
      //     })
      //   }else if(res.data.ResultInfo.ErrCode===1){
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false
      //     });
      //   }else{
      //     Toast.info(res.data.ResultInfo.Message, 3);
      //     thast.setState({
      //       show:false
      //     });
      //   }
      // })

      this.setState({ 
        filesData:fileList[0],
      })
    }
  }

  handleChange = ({file,fileList}) => {
  };

  copyUrl2(){
    let j= copy(this.ahrfCode.innerHTML)
    Toast.success('已复制好，可贴粘', 3);
  }

  submitnot(){
    this.setState({
      show:true,
    })
    const that=this
    const {StudentID}=this.props.types.Student
    const {currenC1,currenC}=this.state
    if(currenC){
      edictTex(this.props.types.Works.WorksID,currenC,currenC1).then((res)=>{
        Toast.success('提交成功', 3);
        this.props.getStated(StudentID,that)
        this.setState({
          show:false,
          canEl:true,
          currenC2:currenC,
          currenC3:currenC1
        })
      })
    }else{
      const {StudentID}=this.state.useData
      this.setState({
        show:false,
      })
      const that=this
      this.props.getStated(StudentID,that)
      Toast.fail('请填写视频中所表演的节目名字', 3)
    }
  }

  componentDidUpdate(){
    this.props.types.Works&&saveInforScan(this.props.types.Works.WorksID,this.props.types.Works.WorksID)
  }

  render() {
    const { imageUrl,show ,canEl} = this.state;
    const {StudentName,OrgName} = this.state.useData
    // this.props.saveInforScan()
    return (
      <div className="loadbac1 load-scoll1">
        {show&&<div>
          <div className="ab-modal-mask"></div>
          <div className="ab-modal-warp ab-warp spd">
            <div>
              <img src={require('../../../assets/images/trans.png')} alt="" srcSet=""/>
                <div>
                  上传中...<br></br>请勿关闭此页面
                </div>
            </div>
					</div>
        </div>}
        <img className="load-logo1" src={require('../../../assets/pc/upTop-26.png')} alt=""></img>
        <div className="sef-head1">
            <div className="pc-topTips">
                <h1>恭喜宝宝进入决赛预选!</h1>
                <h5>请先确认您的个人信息</h5>
                <div>
                    <div>姓名:<a>{StudentName}</a></div>
                    <div> 中心:<a>{OrgName}</a></div>
                    <div>学习账号:<a>{this.props.types.Student?this.props.types.Student.StudentCode:''}</a></div>
                </div>
            </div>
        </div>
        <div className="form-content1">
          <div className="tab-lit-cont1 apply player1">
            <div className="up-bac1">
                <div className="up-sp1">
                  {this.props.types.OpenRematch&&<Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    accept="video/*"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    previewFile={this.previewFiled}
                  >
                    <div className='replaus'>
                    <img src={require('../../../assets/pc/replay-25.png')}></img>
                  </div> 
                  </Upload>}
                    <Player
                      playsInline={true}
                      ref={player => {
                        this.player = player;
                      }}
                      autoPlay
                      src={this.props.types.Works&&this.props.types.Works.NetUrl?this.props.types.Works.NetUrl:''}
                    >
                      <BigPlayButton position="center" />
                        <source src={this.props.types.Works&&this.props.types.Works.NetUrl?this.props.types.Works.NetUrl:''} />
                      <ControlBar autoHide={false} />
                    </Player>
                </div>     
            </div>
           <div className="up-insert1 podw esd">
              <div>*</div>
              <textarea placeholder="请填写视频中所表演的节目名字" value={canEl?(this.props.types.Works?this.props.types.Works.WorksName:''):this.state.currenC} name="" cols="30" rows="2" onChange={this.textarea}></textarea>
                <p><span>{canEl?(this.props.types.Works?(this.props.types.Works.WorksName?this.props.types.Works.WorksName.length:'0'):'0'):(this.state.currenC?this.state.currenC.length:'0')}</span>/<span>30</span></p>
              {canEl&&this.props.types.OpenRematch&&<div onClick={this.edict} className="reEldi it0">
                <img src={require('../../../assets/images/reEld-28.png')}></img> 重新编辑</div>}
            </div>
            <div className="up-insert1 up-insert2 podw">
              <div> </div>
              <textarea placeholder="请写下你的参赛宣言,让你的优秀被更多人发现!" name="" value={canEl?(this.props.types.Works?this.props.types.Works.WorksDescribe:''):this.state.currenC1} cols="30" rows="4" onChange={this.textarea1}></textarea>
              <p><span>{canEl?(this.props.types.Works?(this.props.types.Works.WorksDescribe?this.props.types.Works.WorksDescribe.length:'0'):'0'):(this.state.currenC1?this.state.currenC1.length:'0')}</span>/<span>50</span></p>
              {canEl&&this.props.types.OpenRematch&&<div onClick={this.edict} className="reEldi it1"><img src={require('../../../assets/images/reEld-28.png')}></img> 重新编辑</div>}
            </div>
            <div className='button-grop'> 
                <button className="btn-seach21">{this.props.types.Student?this.props.types.Student.GroupName:'暂无编组'}</button>
                <AbieButton className="btn-seach22" onClick={this.submitnot}>提交作品</AbieButton>
            </div>
            <div className="botsd">
                如发现你的参赛信息和实际不符，请致电：400 088 1298
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,types,detail}} = state;
  return {bannerResource,types,detail}
};

const mapDispatchToProps =  {
  banners,
  getStated,
};

export default connect(mapStateToProps, mapDispatchToProps)(MydetalPc);