import React, {useRef,Component} from 'react'
import PropTypes from "prop-types";
import { Toast} from 'antd-mobile';
import {formatSeconds} from '../../../../../shared/utils/common'

import './index.scss'

export default class AbieVideo extends Component{
  static propTypes = {
    className: PropTypes.string,
    videoUrl:PropTypes.string,
    videoHeight:PropTypes.string,
    playsed:PropTypes.func,
    aotpPaly:PropTypes.bool,
    noBIge:PropTypes.bool
  };

  static defaultProps = {
    className: '',
    videoUrl:'',
    videoHeight:'',
    aotpPaly:true,
    noBIge:true
  };

  constructor(props){
    super(props);
    const methods = ['playStart','handleOk','paused','onPaused','launchFull'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state={
      pauseBlo:true,
      playPase:true,
      cssText:'width:100%;height:100%;overflow:hidden;',
      currentTime:''
    };
  }

  invokeFieldOrMethod = function(element, method) {
    var usablePrefixMethod;
    ["webkit", "moz", "ms", "o", ""].forEach(function(prefix) {
      if (usablePrefixMethod) return;
      if (prefix === "") {
          // 无前缀，方法首字母小写
        method = method.slice(0,1).toLowerCase() + method.slice(1);   
      }
      var typePrefixMethod = typeof element[prefix + method];
      if (typePrefixMethod + "" !== "undefined") {
        if (typePrefixMethod === "function") {
          usablePrefixMethod = element[prefix + method]();
        } else {
          usablePrefixMethod = element[prefix + method];
        }
      }
    });
    return usablePrefixMethod;
  };
  launchFullscreen(element){
   //此方法不可以在異步任務中執行，否則火狐無法全屏
    if(element.requestFullscreen) {
      element.requestFullscreen();
    } else if(element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if(element.msRequestFullscreen){ 
      element.msRequestFullscreen();  
    } else if(element.oRequestFullscreen){
       element.oRequestFullscreen();
   }
   else if(element.webkitRequestFullscreen)
    {
      element.webkitRequestFullScreen();
    }else{
      Toast.info('res.data.ResultInfo.Message', 1);
      const docHtml  = document.documentElement;
      const docBody  = document.body;
      const videobox  = this.videobox;
      const  cssText = this.state.cssText
      docHtml.style.cssText = cssText;
      docBody.style.cssText = cssText;
      videobox.style.cssText = cssText+';'+'margin:0px;padding:0px;';
      document.IsFullScreen = true;
     }
  }

  exitFullscreen(){
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if(document.oRequestFullscreen){
      document.oCancelFullScreen();
    }else if (document.webkitExitFullscreen){
      document.webkitExitFullscreen();
    }else{
      
      var docHtml  = document.documentElement;
      var docBody  = document.body;
      var videobox  = this.videobox;
      
      docHtml.style.cssText = "";
      docBody.style.cssText = "";
      videobox.style.cssText = "";
      document.IsFullScreen = false;
   }
 }

 launchFull(){
   let thiat=this
    this.launchFullscreen(this.abieVi); 
    window.setTimeout(function exit(){
      if(thiat.invokeFieldOrMethod(document,'FullScreen') || thiat.invokeFieldOrMethod(document,'IsFullScreen') || document.IsFullScreen)
    {
      thiat.exitFullscreen();
    }
    },5*10000);
  }

  onPaused(){
    this.abieVi.pause()
  }
  playStart(){
    this.abieVi.play()
    this.setState({
      pauseBlo:false,
      playPase:false,
    })
  }
  handleOk(){

  }
  paused(){
    this.setState({
      pauseBlo:true,
      playPase:true,
    })
  }

  componentDidMount(){
    this.abieVi.ontimeupdate=(()=>{
      this.abieVi&&this.setState({
        currentTime:this.abieVi.currentTime,
        duration:this.abieVi.duration
      })
      this.abieVi&&this.abieVi.currentTime>0&&
        this.setState({
          pauseBlo:false,
          playPase:false
        })
    })
  }

  render(){
    const{className,videoHeight,videoUrl,aotpPaly,noBIge}=this.props
    const{pauseBlo,playPase}=this.state
    return (
      <div className={className}>
        <div onClick={this.onPaused} ref={(res)=>this.videobox=res}>
          <video
            height={videoHeight}
            poster=""
            autoPlay={aotpPaly}
            preload="auto"
            x-webkit-airplay="allow"
            // x5-video-player-type="h5"
            // x5-video-player-fullscreen="true"
            // x5-video-orientation="portraint"
            // playsInline={true}
            // webkit-playsinline=''
            x5-playsinline="" 
            playsinline="" 
            webkit-playsinline="" 
            src={videoUrl}
            ref={(res)=>this.abieVi=res}
            onPause={this.paused}
          > 
          </video>
        </div>
        {pauseBlo&&<div className="bigPlay" onClick={this.playStart}>
          <img src={require('../../../../../assets/images/play-26.png')} alt=""/>
        </div>}
        <div className="toolbars">
          <div>
            {playPase?<img onClick={this.playStart} src={require('../../../../../assets/images/play_lit.png')} alt=""/>:
            <img onClick={this.onPaused} src={require('../../../../../assets/images/pause_lit.png')} alt=""/>}
          </div>
          <div>{formatSeconds(this.state.currentTime)}</div>
          <div className="line-middle">
            <div style={{width:(this.state.currentTime/this.state.duration)*100+"%"}}></div>
          </div>
          <div>{formatSeconds(this.state.duration)}</div>
          {noBIge&&<div onClick={this.launchFull}><img src={require('../../../../../assets/images/trBig.png')} alt=""/></div>}
        </div>
      </div>
    )
  }
}
