import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import { Toast} from 'antd-mobile';

import {ispone} from '../../../shared/regExp'
import AbieButton from './kits/button'
import{banners,submited,getPassCode} from '../actions/index'
import {ismobile,isWeixing} from "../../../shared/utils/common"
import '../pc.scss';

class PcIsloads extends Component {
  constructor(props) {
    super(props);
    const methods = ['timeClick','poneChange','submit','ridosed','yzChange','onBlurs'];
    methods.forEach((item) => this[item] = this[item].bind(this));
    this.state = {
      times:'获取验证码',
      stime:true,
      inputValue:'',
      ridosed:false,
      yzValue:'',
      yzBlo:false,
      pass:'',
    };
  }

  timeClick(){
  const valueBool=this.state.inputValue
  if(ispone(valueBool)){
    getPassCode(valueBool).then((res)=>{
      if(res.data.ResultInfo.ErrCode===0){
        this.setState({
          pass:res.data.ResultInfo.Data
        })
        if(this.state.times==='获取验证码'||this.state.times==='重新获取'){
           var sta=60
           let pop=this
           let ppts=setInterval(function(){
             sta-=1
             pop.setState({
             times:sta+"s"
             })
             if(sta===0){
               pop.setState({
                 times:'重新获取',
                 stime:true
               })
               clearInterval(ppts)
             }
           },1000);
           this.setState({
             stime:false,
             times:60+"s",
           })
       }
      }else if(res.data.ResultInfo.ErrCode===1){
        Toast.fail(res.data.ResultInfo.Message, 3);
      }else{
        this.setState({
          pass:res.data.ResultInfo.Data
        })
      }
    })
    }else{
      Toast.fail('请输入正确的手机号码', 3);
    }
  }

  poneChange(e){
    e.target.value.length<12&&this.setState({
      inputValue:e.target.value
    })
  }
  yzChange(e){
    e.target.value.length<5&&this.setState({
      yzValue:e.target.value
    })

  }

  onBlurs(){    
    setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, scrollTop)
    }, 200)
  }

  submit(){
    if(ispone(this.mobile.value)){
      //////////////////////////////////////////////////////////////////////////////////////////////////上线添加验证码验证
      if((this.verificationCode.value.length!=4||this.verificationCode.value!=this.state.pass)){
        Toast.fail('请输入正确的验证码', 3);
      }else{
        submited(this.mobile.value).then((res)=>{
          //1 注册未绑定 2初赛未报名 3 初赛已报名 4 复赛未上传 5 复赛已上传 6 决赛未上传 7 决赛已上传 8
          localStorage.setItem("useData", JSON.stringify(res.data.ResultInfo.Data));
          const {Status}=res.data.ResultInfo.Data
          console.log(Status,12)
          switch (Status) {
            case 0:                 
              return Toast.fail('还未绑定账号,请登录移动端绑定账号', 3);
            case 1:                 
              return Toast.fail('未绑定账号,请耐心等待,我们老师会联系您!', 3);
            case 2:
              return Toast.fail('初赛未报名,请登录移动端报名', 3);
            case 3:
              return Toast.fail('初赛已报名,复赛通道未来开启', 3);
            case 4:
              return this.props.history.push({pathname: `/uploadPc/${res.data.ResultInfo.Data.StudentID}`})
            case 5:
              return this.props.history.push({pathname: `/mydetailPc/${res.data.ResultInfo.Data.StudentID}`})
            case 6:
              return this.props.history.push({pathname: `/uploadPc/${res.data.ResultInfo.Data.StudentID}`})
            case 7:
              return this.props.history.push({pathname: `/mydetailPc/${res.data.ResultInfo.Data.StudentID}`})
            default:
              return ;
          }
        })
        
      }
    }else{
      Toast.fail('请输入正确的手机号码', 3);
    }
  }

  ridosed(){
    this.setState({
      ridosed:true
    })
  }

  componentDidMount() {
    // // const {banners} = this.props;
    // banners();
    localStorage.removeItem("useData");
    document.title = `登录`;
    if(ismobile())this.props.history.push({pathname: `/load`});
  };

  render() {
    return (
      <div className="loadbac0">
        <div className="spBody">
          <img className="load-logo1" src={require('../../../assets/pc/spOt.png')} alt=""></img>
          <div>
            <img className="load-logo1" src={require('../../../assets/pc/pcLogo.png')} alt=""></img>
            <div className="form-data1">
              <div>
                <label><img src={require('../../../assets/images/aname.png')} alt=""/></label>
                <input onBlur={this.onBlurs} value={this.state.inputValue} onChange={this.poneChange} ref={(input)=>{this.mobile=input}} placeholder="手机号" />
                <button className={this.state.stime?"":"btn-disab1"} onClick={this.timeClick}>{this.state.times}</button>
              </div>
              <div>
                <label><img className="scods12" src={require('../../../assets/images/apassword.png')} alt=""/></label>
                <input onBlur={this.onBlurs} value={this.state.yzValue} ref={(input)=>{this.verificationCode=input}} onChange={this.yzChange}  placeholder="请输入验证码"/>
              </div>
            </div>
            <p className="spPs">请使用移动端登录的手机号</p>
            <div>
              <AbieButton  onClick={this.submit} className="load-sd1">登录</AbieButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {speakout: {bannerResource,islogin}} = state;
  return {bannerResource,islogin}
};

const mapDispatchToProps =  {
  banners
};

export default connect(mapStateToProps, mapDispatchToProps)(PcIsloads);